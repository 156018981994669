/**
 * @copyright Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */


'use strict';

import superagent from 'superagent';

import config from '../../../config';
import {supportsLocalStorage} from '../utils';
import * as cache from '../cache';
import AccountActions from '../actions/AccountActions';

// eslint-disable-next-line
export async function post(authenticated, endpoint, requestBody, query = {}, logoutOn401 = true) {
    if (supportsLocalStorage()) {
        if (authenticated) {
            // TODO: add some utils for encoding / decoding tokens
            const _token = cache.get(config.constants.CACHE_KEY_TOKEN);
            const _apiKey = cache.get(config.constants.CACHE_KEY_API);
            if (_token) {
                try {
                    const res = await superagent
                        .post(`${endpoint}`)
                        .send(requestBody)
                        .query(generateQueryString(query))
                        .set('Accept', 'application/json')
                        .set('Content-Type', 'application/json')
                        .set('Authorization', _token.id)
                        .set('x-api-key', _apiKey ? _apiKey.key : null);

                    if (res.body && res.body.error) {
                        // console.error(res.body.error);
                        throw new Error(res.body.error);
                    }

                    return res.body;
                } catch (err) {
                    if (err && err.status === 401 && logoutOn401) {
                        // TODO: is there a need to defer?
                        AccountActions.logout();
                    }
                    // console.log(err.response.body);
                    throw err;
                }
                // superagent
                //     .post(`${endpoint}`)
                //     .send(requestBody)
                //     .query(generateQueryString(query))
                //     .set('Accept', 'application/json')
                //     .set('Content-Type', 'application/json')
                //     .set('Authorization', _token.id)
                //     .end((err, res) => {
                //         if (err && err.status === 401 && logoutOn401) {
                //             // TODO: is there a need to defer?
                //             AccountActions.logout();
                //         }
                //         if (err && res) {
                //             console.error(res.body.error);
                //             throw new Error(res.body.error);
                //         } else if (err) {
                //             console.error(err);
                //             throw err;
                //         }
                //         return res.body;
                //     });
            } else {
                throw new Error('Unable to make request, Authentication Required');
            }
        } else {
            try {
                const res = await superagent
                    .post(`${endpoint}`)
                    .send(requestBody)
                    .query(generateQueryString(query))
                    .set('Accept', 'application/json')
                    .set('Content-Type', 'application/json');

                if (res.body && res.body.error) {
                    // console.error(res.body.error);
                    throw new Error(res.body.error);
                }

                return res.body;
            } catch (err) {
                if (err && err.status === 401 && logoutOn401) {
                    AccountActions.logout();
                } else {
                    // console.error(err);
                    throw err;
                }
            }
            // superagent
            //     .post(`${endpoint}`)
            //     .send(requestBody)
            //     .query(generateQueryString(query))
            //     .set('Accept', 'application/json')
            //     .set('Content-Type', 'application/json')
            //     .end((err, res) => {
            //         if (err && err.status === 401 && logoutOn401) {
            //             AccountActions.logout();
            //         }
            //         if (err && res) {
            //             console.error(res.body.error);
            //             throw new Error(res.body.error);
            //         } else if (err) {
            //             console.error(err);
            //             throw err;
            //         }
            //         return res.body;
            //     });
        }
    } else {
        throw new Error('Unhandled Exception, Browser not Supported');
    }
}

// eslint-disable-next-line
export async function get(authenticated, endpoint, query = {}, logoutOn401 = true) {
    if (supportsLocalStorage()) {
        if (authenticated) {
            // TODO: add some utils for encoding / decoding tokens
            const _token = cache.get(config.constants.CACHE_KEY_TOKEN);
            const _apiKey = cache.get(config.constants.CACHE_KEY_API);
            if (_token) {
                try {
                    const res = await superagent
                        .get(`${endpoint}`)
                        .query(generateQueryString(query))
                        .set('Accept', 'application/json')
                        .set('Content-Type', 'application/json')
                        .set('Authorization', _token.id)
                        .set('x-api-key', _apiKey ? _apiKey.key : null);

                    if (res.body.error) {
                        // console.error(res.body.error);
                        throw new Error(res.body.error);
                    }

                    return res.body;
                } catch (err) {
                    if (err && err.status === 401 && logoutOn401) {
                        // TODO: is there a need to defer?
                        AccountActions.logout();
                    }
                    // console.error(err);
                    throw err;
                }
            } else {
                throw new Error('Unable to make request, Authentication Required');
            }
        } else {
            try {
                const res = await superagent
                    .get(`${endpoint}`)
                    .query(generateQueryString(query))
                    .set('Accept', 'application/json')
                    .set('Content-Type', 'application/json');

                if (res.body.error) {
                    // console.error(res.body.error);
                    throw new Error(res.body.error);
                }

                return res.body;
            } catch (err) {
                if (err && err.status === 401 && logoutOn401) {
                    AccountActions.logout();
                } else {
                    // console.error(err);
                    throw err;
                }
            }
        }
    } else {
        throw new Error('Unhandled Exception, Browser not Supported');
    }
}

export function generateQueryString(query) {
    const queryKeys = Object.keys(query);
    const queryString = queryKeys.map((key, idx) => {
        const value = query[key];
        if (value === undefined) {
            return '';
        }
        if (idx === 0) {
            return `${encodeURIComponent(key)}=${typeof value === 'object' ? encodeURIComponent(JSON.stringify(value)) : encodeURIComponent(value)}`;
        }
        return `&${encodeURIComponent(key)}=${typeof value === 'object' ? encodeURIComponent(JSON.stringify(value)) : encodeURIComponent(value)}`;
    }).join('');
    return queryString;
}
