/**
 * Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

/* eslint-disable */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Row,
    Col
} from 'reactstrap';

import LoadTheme from '../LoadTheme';
import Spinner from '../Spinner';

import AccountStore from '../../stores/AccountStore';
import AccountActions from '../../actions/AccountActions';
import {logout} from '../../services/AccountService';

export default class Offsidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            activeTab: 'account',
            offsidebarReady: false,
            accountStore: AccountStore.getState(),
        };

        this.onChange = (state) => {
            // We could be more aggressive about how we update this (as it's likely not to change often)
            if (state.authenticated) {
                this.setState({
                    accountStore: state
                });
            }
        };
    }

    componentDidMount() {
        AccountStore.listen(this.onChange);
        // When mounted display the offsidebar
        this.setState({offsidebarReady: true});
    }

    componentWillUnmount() {
        AccountStore.unlisten(this.onChange);
    }

    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    };

    handleSignOut = () => {
        this.setState({
            loading: true
        }, () => {
            logout()
                .then(() => {
                    // Close the offside bar
                    document.getElementById('offsidebar-toggler').click();
                    AccountActions.logout();
                })
                .catch((error) => {
                    console.log(error);
                });
        });
    };

    render() {
        return (
            this.state.offsidebarReady
            && <aside className="offsidebar">
                {/* START Off Sidebar (right) */}
                <nav>
                    <div>
                        {/* Nav tabs */}
                        <Nav tabs justified>
                            <NavItem>
                                <NavLink
                                    className={this.state.activeTab === 'account' ? 'active' : ''}
                                    onClick={() => {
                                        this.toggle('account');
                                    }}
                                >
                                    <em className="icon-user fa-lg"/>
                                </NavLink>
                            </NavItem>
                            {/* Disable second tab 'preferences'*/}
                            {/*<NavItem>*/}
                                {/*<NavLink*/}
                                    {/*className={this.state.activeTab === 'preferences' ? 'active' : ''}*/}
                                    {/*onClick={() => {*/}
                                        {/*this.toggle('preferences');*/}
                                    {/*}}*/}
                                {/*>*/}
                                    {/*<em className="icon-equalizer fa-lg"/>*/}
                                {/*</NavLink>*/}
                            {/*</NavItem>*/}
                        </Nav>
                        {/* Tab panes */}
                        <TabContent activeTab={this.state.activeTab}>
                            <AccountTab
                                account={this.state.accountStore.account}
                                name={this.state.accountStore.name}
                                loading={this.state.loading}
                                handleSignOut={this.handleSignOut}/>
                            <PreferencesTab/>
                        </TabContent>
                    </div>
                </nav>
            </aside>
        );
    }
}

const AccountTab = props => (
    <TabPane tabId="account">
        <h3 className="text-center text-thin mt-4">{props.name}</h3>
        <h5 className="text-center text-muted text-thin">{props.account.hid}</h5>
        <div className="p-2">
            <h4 className="text-muted text-thin">Profile</h4>
            <div className="clearfix">
                <p className="float-left">Email</p>
                <p className="float-right">{props.account.email}</p>
            </div>
            <div className="clearfix">
                <p className="float-left">Phone</p>
                <p className="float-right">{props.account.profile.phone ? props.account.profile.phone : 'N/A'}</p>
            </div>
        </div>
        <div className="p-2">
            <h4 className="text-muted text-thin">Organization</h4>
            <div className="clearfix">
                <p className="float-left">Name</p>
                <p className="float-right">{props.account.organization.name}</p>
            </div>
            <div className="clearfix">
                <p className="float-left">Role</p>
                <p className="float-right">{props.account.roles.toString()}</p>
            </div>
        </div>
        <Row>
            <Col className="col-md-6 offset-md-3">
                {!props.loading && (
                    <div className="px-3 py-4 text-center">
                        <Button className="btn btn-purple btn-sm text-thin" block onClick={props.handleSignOut}>Sign Out</Button>
                    </div>
                )}
                {props.loading && (
                    <div className="px-3 py-4">
                        <Button style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }} className="btn btn-purple btn-sm" block disabled onClick={props.handleSignOut}><Spinner size={20} color={'white'}/></Button>
                    </div>
                )}
            </Col>
        </Row>
    </TabPane>
);


AccountTab.propTypes = {
    account: PropTypes.object,
    name: PropTypes.string,
    loading: PropTypes.bool,
    handleSignOut: PropTypes.func
};

const PreferencesTab = props => ( // eslint-disable-line no-unused-vars
    <TabPane tabId="preferences">
        <h3 className="text-center text-thin mt-4">Preferences</h3>
        <div className="p-2">
            {/*<h4 className="text-muted text-thin">Theme</h4>*/}
            <div className="row row-flush mb-2">
                <div className="col mb-2">
                    <div className="setting-color">
                        <LoadTheme default={true} theme="C">
                            <div/>
                            {/*<label>*/}
                                {/*<input type="radio" name="setting-theme"/>*/}
                                {/*<span className="split">*/}
                                    {/*<span className="color bg-info-dark"></span>*/}
                                    {/*<span className="color bg-info"></span>*/}
                                {/*</span>*/}
                                {/*<span className="color bg-gray-dark"></span>*/}
                            {/*</label>*/}
                        </LoadTheme>
                    </div>
                </div>
            </div>
        </div>
        <div className="p-2">
            <h4 className="text-muted text-thin">Notifications</h4>
            <div className="clearfix">
                <p className="float-left">Email</p>
                <div className="float-right">
                    <label className="switch">
                        <input id="chk-fixed" type="checkbox" name="layout-fixed" defaultChecked/>
                        {/* TODO: Need to understand ref tag; Offsidebar appears to do ALOT of re-rendering */}
                        {/* <input id="chk-fixed" type="checkbox" name="layout-fixed" defaultChecked ref={() => console.log('switched')}/> */}
                        <span></span>
                    </label>
                </div>
            </div>
            <div className="clearfix">
                <p className="float-left">SMS</p>
                <div className="float-right">
                    <label className="switch">
                        <input id="chk-fixed" type="checkbox" name="layout-fixed"/>
                        {/* TODO: Need to understand ref tag; Offsidebar appears to do ALOT of re-rendering */}
                        {/* <input id="chk-fixed" type="checkbox" name="layout-fixed" ref={() => console.log('switched')}/> */}
                        <span></span>
                    </label>
                </div>
            </div>
        </div>
        <div className="p-2">
            <h4 className="text-muted text-thin">Options</h4>
            <div className="clearfix">
                <p className="float-left">Hot Keys</p>
                <div className="float-right">
                    <label className="switch">
                        <input id="chk-fixed" type="checkbox" name="layout-fixed" defaultChecked/>
                        {/* TODO: Need to understand ref tag; Offsidebar appears to do ALOT of re-rendering */}
                        {/* <input id="chk-fixed" type="checkbox" name="layout-fixed" defaultChecked ref={() => console.log('switched')}/> */}
                        <span></span>
                    </label>
                </div>
            </div>
            <div className="clearfix">
                <p className="float-left">Language</p>
                <div className="float-right">
                    <label className="switch">
                        <input id="chk-fixed" type="checkbox" name="layout-fixed"/>
                        {/* TODO: Need to understand ref tag; Offsidebar appears to do ALOT of re-rendering */}
                        {/* <input id="chk-fixed" type="checkbox" name="layout-fixed" ref={() => console.log('switched')}/> */}
                        <span></span>
                    </label>
                </div>
            </div>
            <p className="text-danger text-center">Just a heads up were still working on some of these items. Don&apos;t
                be dismayed though we&apos;ll get to it soon!</p>
        </div>
    </TabPane>
);
