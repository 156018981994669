/**
 * @copyright Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

import React, { Component } from 'react';
import {
    Route,
    Switch
} from 'react-router-dom';
import ProductContainer from './ProductContainer.jsx';
import {URLS} from '../../constants';

export default class ProductRouter extends Component {
    static propTypes = {

    };

    static defaultProps = {

    };

    render() { // eslint-disable-line
        return (
            <Switch>
                <Route exact path={`${URLS.common.products.base}/feeling-lucky`} component={ProductContainer} />
                <Route exact path={`${URLS.common.products.base}/:hid`} component={ProductContainer} />
            </Switch>

        );
    }
}
