/**
 * Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

import config from '../../../../config';
import {get} from '../../services/ApiService';

const PRODUCTS_FEELING_LUCKY_ENDPOINT = `${config.services.ATLAS.href}/products/feeling-lucky`;

export async function getProduct() { // eslint-disable-line import/prefer-default-export
    return [
        {
            id: 1,
            statusId: 'ACTIVE',
            name: 'Foo Baz',
            email: 'foo@bar.com'
        },
        {
            id: 2,
            statusId: 'DISABLED',
            name: 'Jack Sparrow',
            email: 'jack.sparrow@bar.com'
        },
        {
            id: 3,
            statusId: 'ACTIVE',
            name: 'Leonardo DaVinci',
            email: 'davinci@bar.com'
        }
    ];
    // return get(true, TEAM_ENDPOINT, {}, true);
}

export async function feelingLucky() { // eslint-disable-line import/prefer-default-export
    return get(true, PRODUCTS_FEELING_LUCKY_ENDPOINT, {}, true);
}
