/**
 * @copyright Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

import React, {Component} from 'react'; // eslint-disable-line no-unused-vars

import CreateApplication from './CreateApplication.jsx';

/**
 * ExploreContainer
 */
export default class CreateApplicationContainer extends Component {
    static propTypes = {};

    static defaultProps = {};

    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    }

    render() { // eslint-disable-line
        return (
            <div>
                <CreateApplication {...this.props} />
            </div>
        );
    }
}
