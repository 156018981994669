/**
 * @copyright Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

/* eslint-disable */

'use strict';

import React, {Component} from 'react';
import {withNamespaces, Trans} from 'react-i18next';
import {
    Row,
    Col, Card, CardBody,
} from 'reactstrap';
// EasyPieChart
import EasyPieChart from 'easy-pie-chart';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themes_animated);

import ContentWrapper from '../../../common/components/layout/ContentWrapper';
import Sparkline from '../../../common/components/Sparklines';
import FlotChart from '../../../common/components/charts/Flot';
import VectorMap from '../../../common/components/maps/VectorMap';

const defaultColors = {
    markerColor: '#23b7e5', // the marker points
    bgColor: 'transparent', // the background
    scaleColors: ['#878c9a'], // the color of the region in the serie
    regionFill: '#bbbec6' // the base region color
};

const RegionValues = {
    CA: 11100, // Canada
    DE: 2510, // Germany
    FR: 3710, // France
    AU: 5710, // Australia
    GB: 8310, // Great Britain
    RU: 9310, // Russia
    BR: 6610, // Brazil
    IN: 7810, // India
    CN: 4310, // China
    US: 839, // USA
    SA: 410 // Saudi Arabia
};

class Dashboard extends Component {
    state = {
        splineData: [{
            label: 'Home',
            color: '#1ba3cd',
            data: [
                ['1', 38],
                ['2', 40],
                ['3', 42],
                ['4', 48],
                ['5', 50],
                ['6', 70],
                ['7', 145],
                ['8', 70],
                ['9', 59],
                ['10', 48],
                ['11', 38],
                ['12', 29],
                ['13', 30],
                ['14', 22],
                ['15', 28]
            ]
        }, {
            label: 'Overall',
            color: '#3a3f51',
            data: [
                ['1', 16],
                ['2', 18],
                ['3', 17],
                ['4', 16],
                ['5', 30],
                ['6', 110],
                ['7', 19],
                ['8', 18],
                ['9', 110],
                ['10', 19],
                ['11', 16],
                ['12', 10],
                ['13', 20],
                ['14', 10],
                ['15', 20]
            ]
        }],
        splineOptions: {
            series: {
                lines: {
                    show: false
                },
                points: {
                    show: true,
                    radius: 4
                },
                splines: {
                    show: true,
                    tension: 0.4,
                    lineWidth: 1,
                    fill: 0.5
                }
            },
            grid: {
                borderColor: '#eee',
                borderWidth: 1,
                hoverable: true,
                backgroundColor: '#fcfcfc'
            },
            tooltip: true,
            tooltipOpts: {
                content: (label, x, y) => `${x} : ${y}`
            },
            xaxis: {
                tickColor: '#fcfcfc',
                mode: 'categories'
            },
            yaxis: {
                min: 0,
                max: 150, // optional: use it for a clear represetation
                tickColor: '#eee',
                // position: 'right' or 'left',
                tickFormatter: v => v
            },
            shadowSize: 0
        },

        // VectorMap
        series: {
            regions: [{
                values: RegionValues,
                scale: defaultColors.scaleColors,
                normalizeFunction: 'polynomial'
            }]
        },
        markers: [
            {latLng: [41.90, 12.45], name: 'Vatican City'},
            {latLng: [43.73, 7.41], name: 'Monaco'},
            {latLng: [-0.52, 166.93], name: 'Nauru'},
            {latLng: [-8.51, 179.21], name: 'Tuvalu'},
            {latLng: [7.11, 171.06], name: 'Marshall Islands'},
            {latLng: [17.3, -62.73], name: 'Saint Kitts and Nevis'},
            {latLng: [3.2, 73.22], name: 'Maldives'},
            {latLng: [35.88, 14.5], name: 'Malta'},
            {latLng: [41.0, -71.06], name: 'New England'},
            {latLng: [12.05, -61.75], name: 'Grenada'},
            {latLng: [13.16, -59.55], name: 'Barbados'},
            {latLng: [17.11, -61.85], name: 'Antigua and Barbuda'},
            {latLng: [-4.61, 55.45], name: 'Seychelles'},
            {latLng: [7.35, 134.46], name: 'Palau'},
            {latLng: [42.5, 1.51], name: 'Andorra'}
        ],
        options: {
            map: 'world_mill_en',
            backgroundColor: defaultColors.bgColor,
            zoomMin: 1,
            zoomMax: 8,
            zoomOnScroll: false,
            regionStyle: {
                initial: {
                    fill: defaultColors.regionFill,
                    'fill-opacity': 1,
                    stroke: 'none',
                    'stroke-width': 1.5,
                    'stroke-opacity': 1
                },
                hover: {
                    'fill-opacity': 0.8
                },
                selected: {
                    fill: 'blue'
                },
                selectedHover: {}
            },
            focusOn: {
                x: 0.4,
                y: 0.6,
                scale: 1
            },
            markerStyle: {
                initial: {
                    fill: defaultColors.markerColor,
                    stroke: defaultColors.markerColor
                }
            },
            onRegionLabelShow: (e, el, code) => {
                if (RegionValues[code]) el.html(`${el.html()}: ${RegionValues[code]} visitors`);
            }
        },

        dropdownTranslateOpen: false,
        activeTab: 'tasks',
    };

    toggleDDTranslate = () => {
        this.setState({
            dropdownTranslateOpen: !this.state.dropdownTranslateOpen
        });
    };

    toggleTab = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    };

    componentDidMount() {
        // EasyPieChar
        const pieOptions1 = {
            animate: {duration: 800, enabled: true},
            barColor: '#23b7e5',
            trackColor: 'rgba(200,200,200,0.4)',
            scaleColor: false,
            lineWidth: 10,
            lineCap: 'round',
            size: 145
        };
        // eslint-disable-next-line
        new EasyPieChart(this.refs.easypiechart, pieOptions1);

        let chart = am4core.create('chartdiv', am4charts.XYChart);

        chart.paddingRight = 20;

        let data = [];
        let visits = 10;
        for (let i = 1; i < 366; i++) {
            visits += Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10);
            data.push({ date: new Date(2018, 0, i), name: 'name' + i, value: visits });
        }

        chart.data = data;

        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0;

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.tooltip.disabled = true;
        valueAxis.renderer.minWidth = 35;

        let series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.dateX = 'date';
        series.dataFields.valueY = 'value';

        series.tooltipText = '{valueY.value}';
        chart.cursor = new am4charts.XYCursor();

        let scrollbarX = new am4charts.XYChartScrollbar();
        scrollbarX.series.push(series);
        chart.scrollbarX = scrollbarX;

        this.chart = chart;
    }

    componentWillUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
    }

    render() {
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div><Trans i18nKey='admin.routes.dashboard.LEAD'></Trans>
                        <small><Trans i18nKey='admin.routes.dashboard.LEAD_SUB_TEXT'></Trans></small>
                    </div>
                </div>
                <Row>
                    <div className="col-xl-3 col-md-6">
                        {/* START widget */}
                        <div className="card bg-info-light pt-2 b0">
                            <div className="px-2">
                                <em className="icon-cloud-upload fa-lg float-right"></em>
                                <div className="h2 mt0">1700</div>
                                <div className="text-uppercase">Uploads</div>
                            </div>
                            <Sparkline options={{
                                type: 'line',
                                width: '100%',
                                height: '75px',
                                lineColor: '#23b7e5',
                                chartRangeMin: '0',
                                fillColor: '#23b7e5',
                                spotColor: '#23b7e5',
                                minSpotColor: '#23b7e5',
                                maxSpotColor: '#23b7e5',
                                highlightSpotColor: '#23b7e5',
                                highlightLineColor: '#23b7e5',
                                resize: true
                            }} values='2,5,3,7,4,5' style={{marginBottom: '-2px'}}
                            />
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-6">
                        {/* START widget */}
                        <div className="card bg-purple-light pt-2 b0">
                            <div className="px-2">
                                <em className="icon-globe fa-lg float-right"></em>
                                <div className="h2 mt0">700
                                    <span className="text-sm text-white">GB</span>
                                </div>
                                <div className="text-uppercase">Quota</div>
                            </div>
                            <Sparkline options={{
                                type: 'line',
                                width: '100%',
                                height: '75px',
                                lineColor: '#7266ba',
                                chartRangeMin: '0',
                                fillColor: '#7266ba',
                                spotColor: '#7266ba',
                                minSpotColor: '#7266ba',
                                maxSpotColor: '#7266ba',
                                highlightSpotColor: '#7266ba',
                                highlightLineColor: '#7266ba',
                                resize: true
                            }} values='1,4,5,4,8,7,10' style={{marginBottom: '-2px'}}
                            />
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-12">
                        {/* START widget */}
                        <div className="card bg-info-light pt-2 b0">
                            <div className="px-2">
                                <em className="icon-bubbles fa-lg float-right"></em>
                                <div className="h2 mt0">500</div>
                                <div className="text-uppercase">Reviews</div>
                            </div>
                            <Sparkline options={{
                                type: 'line',
                                width: '100%',
                                height: '75px',
                                lineColor: '#23b7e5',
                                chartRangeMin: '0',
                                fillColor: '#23b7e5',
                                spotColor: '#23b7e5',
                                minSpotColor: '#23b7e5',
                                maxSpotColor: '#23b7e5',
                                highlightSpotColor: '#23b7e5',
                                highlightLineColor: '#23b7e5',
                                resize: true
                            }} values='4,5,3,10,7,15' style={{marginBottom: '-2px'}}
                            />
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-12">
                        {/* START widget */}
                        <div className="card bg-purple-light pt-2 b0">
                            <div className="px-2">
                                <em className="icon-pencil fa-lg float-right"></em>
                                <div className="h2 mt0">35</div>
                                <div className='text-uppercase'>Annotations</div>
                            </div>
                            <Sparkline options={{
                                type: 'line',
                                width: '100%',
                                height: '75px',
                                lineColor: '#7266ba',
                                chartRangeMin: '0',
                                fillColor: '#7266ba',
                                spotColor: '#7266ba',
                                minSpotColor: '#7266ba',
                                maxSpotColor: '#7266ba',
                                highlightSpotColor: '#7266ba',
                                highlightLineColor: '#7266ba',
                                resize: true
                            }} values='1,3,4,5,7,8' style={{marginBottom: '-2px'}}
                            />
                        </div>
                    </div>
                </Row>

                <Row>
                    <Col lg={12} md={12}>
                        <Card className="card-default">
                            <Row>
                                <Col lg={12} md={12}>
                                    <CardBody >
                                        <div>
                                            <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>
                                        </div>
                                    </CardBody>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>

                {/* START chart */}
                <Row>
                    <Col lg={9}>
                        <div className="col-xl-12">
                            <div className="card card-default" id="cardChart9">
                                <div className="card-header">
                                    <div className="card-title">Website Performance</div>
                                </div>
                                <div className="card-body">
                                    <FlotChart data={this.state.splineData} options={this.state.splineOptions} height="250px"/>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={3}>
                        {/* START loader widget */}
                        <div className="card card-default">
                            <div className="card-body">
                                <a className="text-muted float-right" href="">
                                    <em className="fa fa-arrow-right"></em>
                                </a>
                                <div className="text-info">Average Monthly Uploads</div>
                                <div className="text-center py-4">
                                    {/* eslint-disable-next-line */}
                                    <div ref="easypiechart" data-percent="70" className="easypie-chart easypie-chart-lg">
                                        <span>70%</span>
                                    </div>
                                </div>
                                <Sparkline options={{
                                    barColor: '#23b7e5',
                                    height: '30',
                                    barWidth: '5',
                                    barSpacing: '2'
                                }} values="5,4,8,7,8,5,4,6,5,5,9,4,6,3,4,7,5,4,7" className="text-center"/>
                            </div>
                            <div className="card-footer">
                                <p className="text-muted">
                                    <em className="fa fa-upload fa-fw"></em>
                                    <span>This Month</span>
                                    <span className="text-dark">1000 Gb</span>
                                </p>
                            </div>
                        </div>
                        {/* END loader widget */}
                    </Col>
                </Row>
                {/* END chart */}
                <Row>
                    <Col lg={12}>
                        <div className="card card-transparent">
                            <VectorMap height="450px" options={this.state.options} series={this.state.series} markers={this.state.markers}/>
                        </div>
                    </Col>
                </Row>
            </ContentWrapper>
        );
    }
}

export default withNamespaces('translations')(Dashboard);
