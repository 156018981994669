/**
 * @copyright Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

import React, { Component } from 'react';
import {
    Redirect,
    Route,
    Switch
} from 'react-router-dom';

import DashboardRouter from './views/dashboard/DashboardRouter.jsx';
import AssetsRouter from './views/assets/AssetsRouter.jsx';
import ExploreRouter from './views/explore/ExploreRouter.jsx';
import ReportsRouter from './views/reports/ReportsRouter.jsx';
import ToolsRouter from './views/tools/ToolsRouter.jsx';
import ProductRouter from '../common/views/product/ProductRouter.jsx';
import ApplicationsRouter from './views/manage/applications/ApplicationsRouter.jsx';
import PlanRouter from './views/manage/plan/PlanRouter.jsx';
import TeamRouter from './views/manage/team/TeamRouter.jsx';

import {URLS} from '../common/constants';

export default class OrganizationRouter extends Component {
    static propTypes = {

    };

    static defaultProps = {

    };

    render() { // eslint-disable-line
        return (
            <Switch>
                <Route path="/org/dashboard" exact component={DashboardRouter}/>
                <Route path="/org/assets" component={AssetsRouter}/>
                <Route path="/org/explore" exact component={ExploreRouter}/>
                <Route path="/org/reports" exact component={ReportsRouter}/>
                <Route path="/org/tools" component={ToolsRouter}/>
                {/* TODO Consolidate to Manage Router */}
                <Route path={URLS.organizations.applications.base} component={ApplicationsRouter}/>
                <Route path="/org/manage/plan" component={PlanRouter}/>
                <Route path="/org/manage/team" component={TeamRouter}/>
                <Route path={URLS.common.products.base} component={ProductRouter}/>
                <Redirect to="/org/dashboard"/>
            </Switch>
        );
    }
}
