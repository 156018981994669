/* eslint-disable */

import React from 'react';
import { translate, Trans } from 'react-i18next';
import ContentWrapper from '../../../common/components/layout/ContentWrapper';
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

class Settings extends React.Component {

    state = {
      dropdownOpen: false
    }

    changeLanguage = lng => {
        this.props.i18n.changeLanguage(lng);
    }

    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    render() {
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>
                        System
                        <small>manage system properties and settings</small>
                    </div>
                    { /* START Language list */ }
                    <div className="ml-auto">
                        <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                            <DropdownToggle>
                                English
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-right-forced animated fadeInUpShort">
                                <DropdownItem onClick={() => this.changeLanguage('en')}>English</DropdownItem>
                                <DropdownItem onClick={() => this.changeLanguage('es')}>Spanish</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                    { /* END Language list */ }
                </div>
                <Row>
                    <Col xs={12} className="text-center">
                        <p className="text-muted">
                            Under Construction. Updates coming soon!
                        </p>
                    </Col>
                </Row>
            </ContentWrapper>
        );
    }
}

export default translate('translations')(Settings);
