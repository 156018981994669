/**
 * @copyright Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

/* eslint-disable */

import _ from 'lodash';
import moment from 'moment';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Button,
    Collapse,
} from 'reactstrap';
import CalendarHeatmap from 'react-calendar-heatmap';
import 'react-calendar-heatmap/dist/styles.css';
import ReactTooltip from 'react-tooltip';

import ContentWrapper from '../../../common/components/layout/ContentWrapper';
import AccountStore from '../../../common/stores/AccountStore';
import {numberFormatter} from '../../../common/utils';
import {APP_COLORS, URLS} from '../../../common/constants';

export default class Dashboard extends Component {
    static propTypes = {
        history: PropTypes.object,
        usageStatistics: PropTypes.object,
    };

    static defaultProps = {};

    constructor(props) {
        super(props);
        this.state = {
            account: AccountStore.getState().account
        };

        this.onChange = state => this.setState({account: state.account});
    }

    componentDidMount() {
        AccountStore.listen(this.onChange);
    }

    componentWillUnmount() {
        AccountStore.unlisten(this.onChange);
    }

    render() { // eslint-disable-line
        const dailyUsageStatistics = this.props.usageStatistics ? this.props.usageStatistics.daily : [];
        const recentUsage = this.props.recentUsage ? _.sortBy(this.props.recentUsage, [(ru => ru.reqOn)]).reverse() : [];
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>Dashboard<small className="mt-1">monitor your database and usage</small></div>
                    {/*<div className="ml-auto">*/}
                        {/*<Button*/}
                            {/*className="text-thin"*/}
                            {/*color="green"*/}
                            {/*size="lg"*/}
                            {/*onClick={() => this.props.history.push(URLS.organizations.applications.createApplication)}*/}
                        {/*>NEW APPLICATION</Button>*/}
                    {/*</div>*/}
                </div>
                <Row>
                    <Col md={12}>
                        <Card className="card-default">
                            <CardHeader>
                                <span className="text-muted"><em>API Usage</em></span>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md={12}>
                                        <CalendarHeatmap
                                            startDate={moment().subtract(1, 'y').format('YYYY-MM-DD')}
                                            endDate={moment().format('YYYY-MM-DD')}
                                            values={dailyUsageStatistics.map((dus) => {
                                                let countMapping;
                                                if (dus.count > 0 && dus.count < 2500) countMapping = 0;
                                                if (dus.count > 2500 && dus.count < 5000) countMapping = 1;
                                                if (dus.count > 5000 && dus.count < 7500) countMapping = 2;
                                                if (dus.count > 7500 && dus.count < 9000) countMapping = 3;
                                                if (dus.count > 9000) countMapping = 4;
                                                return {
                                                    date: dus.date,
                                                    count: countMapping,
                                                    rawCount: dus.count
                                                };
                                            })}
                                            showMonthLabels
                                            classForValue={(value) => {
                                                if (!value) {
                                                    return 'color-empty';
                                                }
                                                return `color-gitlab-${value.count}`;
                                            }}
                                            tooltipDataAttrs={(value) => {
                                                if (!value || !value.date) {
                                                    return null;
                                                }

                                                return {
                                                    'data-tip': `${value.date} has count: ${value.count}`
                                                }
                                            }}
                                        />
                                        <ReactTooltip />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        {/* LEGEND, This is a bit kludgy */}
                                        <div className="mt-2">
                                            <svg height="15">
                                                <rect data-tip='Less than 2.5k API Calls' width={15} height={15} x={0} style={{fill:'#ede9fc'}} />
                                                <rect data-tip='Between 2.5k and 5k API Calls' width={15} height={15} x={17} style={{fill:'#cabcf5'}} />
                                                <rect data-tip='Between 5k and 7.5k API Calls' width={15} height={15} x={34} style={{fill:'#8263e9'}} />
                                                <rect data-tip='Between 7.5k and 9k API Calls' width={15} height={15} x={51} style={{fill:'#451dc9'}} />
                                                <rect data-tip='More than 9k API Calls' width={15} height={15} x={68} style={{fill:'#261070'}} />
                                            </svg>
                                            <span className="text-muted text-sm fa-pull-right">Day boundaries in UTC</span>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="row row-flush mt-3">
                                    <div className="offset-md-3 col-md-2 py-2 br d-flex text-center align-items-center justify-content-center">
                                        <div>
                                            <div className="text-muted text-uppercase text-sm">API Calls</div>
                                            <div className="h1 m-0 text-thin">{this.props.usageStatistics ? numberFormatter(this.props.usageStatistics.agg.apiCallsLast7Days, 1) : 0}</div>
                                            <div className="text-muted text-uppercase text-sm">Last 7 Days</div>
                                        </div>
                                    </div>
                                    <div className="col-md-2 py-2 br d-flex text-center align-items-center justify-content-center">
                                        <div>
                                            <div className="text-muted text-uppercase text-sm">API Calls</div>
                                            <div className="h1 m-0 text-thin">{this.props.usageStatistics ? numberFormatter(this.props.usageStatistics.agg.apiCallsLast30Days, 1) : 0}</div>
                                            <div className="text-muted text-uppercase text-sm">Last 30 Days</div>
                                        </div>
                                    </div>
                                    <div className="col-md-2 py-2 d-flex text-center align-items-center justify-content-center">
                                        <div>
                                            <div className="text-muted text-uppercase text-sm">Active</div>
                                            <div className="h1 m-0 text-thin">2</div>
                                            <div className="text-muted text-uppercase text-sm">Applications</div>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {recentUsage && recentUsage.length === 0 && (
                    <Row>
                        <Col md={12} className="text-center">
                            <p className="text-muted">
                                Looks like you don't have any recent usage. Have you <Link to={URLS.organizations.applications.createApplication}>created an Application</Link> yet?
                            </p>
                        </Col>
                    </Row>
                )}
                {recentUsage && recentUsage.length > 0 && (
                    <Row>
                        <Col md={12}>
                            <div>
                                {recentUsage.map((ru, i) => (
                                    <RecentUsage
                                        key={i}
                                        recentUsage={ru}
                                        indexPos={i}
                                        length={recentUsage.length}
                                    />
                                ))}
                            </div>
                        </Col>
                    </Row>
                )}
            </ContentWrapper>
        );
    }
}

class RecentUsage extends Component {
    static propTypes = {
        recentUsage: PropTypes.object,
        indexPos: PropTypes.number,
        length: PropTypes.number,
    };

    static defaultProps = {};

    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
    }

    render() {
        const ru = this.props.recentUsage;
        let cardClassName = 'card-default mb-0 border-top-0';
        if (this.props.indexPos === 0) cardClassName = 'card-default mb-0';
        return (
            <Card className={cardClassName}>
                {(this.props.indexPos === 0) && (
                    <CardHeader>
                        <span className="text-muted"><em>Recent API Calls</em></span>
                    </CardHeader>
                )}
                <CardHeader onClick={() => this.setState({open: !this.state.open})}>
                    <h4 className="mb-0">{ru.method}
                        <span className="text-thin"> {ru.uri}</span>
                        <span className="text-thin fa-pull-right">{ru.api}</span>
                    </h4>
                    <small className="text-muted">Requested: {moment(ru.reqOn).format('YYYY-MM-DD HH:mm:ss')}</small>
                    <small className="text-muted fa-pull-right">{ru.latency} ms</small>
                </CardHeader>
                <Collapse isOpen={this.state.open}>
                    <CardBody className="text-muted">Keep an eye out for updates! Were working hard on adding in more detail. Get a hold of us <a href="mailto:support@timberlineanaytics.com">here</a> if we can help you out in the near term!</CardBody>
                </Collapse>
            </Card>
        );
    }
}
