/**
 * Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

import 'core-js/es6/string';
import 'core-js/es6/array';
import 'core-js/es6/map';
import 'core-js/es6/set';
import 'raf/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';

import alt from './alt';
import * as AccountService from './js/common/services/AccountService';
import App from './App';

import './i18n';

if (AccountService.isLoggedIn()) {
    // Bootstrap Alt
    const account = AccountService.getAccount();
    alt.bootstrap(JSON.stringify({
        AccountStore: {
            authenticated: true,
            initialized: true,
            account: account,
            name: AccountService.getName(account.profile),
            roleMapping: AccountService.getRoleMapping(account),
            isOwner: AccountService.isOwner(account),
        }
    }));
}

ReactDOM.render(<App/>, document.getElementById('app'));
