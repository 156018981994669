/**
 * @copyright Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

import React, { Component } from 'react';
import {
    Switch,
    Route
} from 'react-router-dom';
import AssetsContainer from './AssetsContainer.jsx';
import CreateAssetContainer from './CreateAssetContainer.jsx';

import {URLS} from '../../../common/constants';

export default class AssetsRouter extends Component {
    static propTypes = {

    };

    static defaultProps = {

    };

    render() { // eslint-disable-line
        const _URLS = URLS.organizations.assets;
        return (
            <Switch>
                <Route path={_URLS.createAsset} exact component={CreateAssetContainer}/>
                <Route path={_URLS.base} component={AssetsContainer} />
            </Switch>
        );
    }
}
