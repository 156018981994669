/**
 * Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

/* eslint-disable */

import React, { Component } from 'react';
import {Input} from 'reactstrap';

import {getCopyright} from '../../utils';
import FormValidator from '../../components/forms/FormValidator';
import {requestResetPassword} from '../../services/AccountService';
import {ToastContainer, toast} from 'react-toastify';
import SpinnerOverlay from "../../components/SpinnerOverlay";
import {Link} from "react-router-dom";

export default class Recover extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            formRecover: {
                email: ''
            }
        };
    }

    /**
     * Validate input using onChange event
     * @param  {String} formName The name of the form in the state object
     * @return {Function} a function used for the event
     */
    validateOnChange = (event) => {
        const input = event.target;
        const form = input.form; // eslint-disable-line prefer-destructuring
        const value = input.type === 'checkbox' ? input.checked : input.value;

        const result = FormValidator.validate(input);

        this.setState({
            [form.name]: {
                ...this.state[form.name],
                [input.name]: value,
                errors: {
                    ...this.state[form.name].errors,
                    [input.name]: result
                }
            }
        });
    };

    onSubmit = (e) => {
        e.preventDefault();
        const form = e.target;
        const inputs = [...form.elements].filter(i => ['INPUT', 'SELECT'].includes(i.nodeName));
        const {errors, hasError} = FormValidator.bulkValidate(inputs);

        if (hasError) {
            this.setState({
                [form.name]: {
                    ...this.state[form.name],
                    errors
                }
            });
        } else {
            this.setState({
                loading: true,
                [form.name]: {
                    ...this.state[form.name],
                    errors
                }
            }, () => {
                requestResetPassword(this.state.formRecover.email)
                    .then(() => {
                        this.setState({loading: false}, () => {
                            toast('Thanks, please check your email.', {
                                type: 'success',
                                hideProgressBar: true,
                                autoClose: false,
                                position: 'top-center'
                            });
                        });
                    })
                    .catch(error => { // eslint-disable-line
                        this.setState({loading: false}, () => {
                            if (error.message === 'Not Found') {
                                toast('Hmm... Double check your email.', {
                                    type: 'info',
                                    hideProgressBar: true,
                                    position: 'top-center'
                                })
                            } else {
                                toast(`There was a problem - ${error.message}`, {
                                    type: 'error',
                                    hideProgressBar: true,
                                    autoClose: false,
                                    position: 'top-center'
                                });
                            }
                        });
                    });
            });
        }
    };

    /* Simplify error check */
    hasError = (formName, inputName, method) => this.state[formName]
        && this.state[formName].errors
        && this.state[formName].errors[inputName]
        && this.state[formName].errors[inputName][method];

    render() {
        return (
            <div>
                <ToastContainer />
                {this.state.loading && <SpinnerOverlay/>}
                <div className="block-center mt-4 wd-xl">
                    {/* START card */}
                    <div className="card card-flat">
                        <div className="card-header text-center" style={{backgroundColor: '#6d48e5'}}>
                            <a href="https://web.rocketiq.io">
                                <img className="block-center rounded" style={{height: 35, width: 35}} src="img/icon-white.png" alt="Logo"/>
                                <span style={{color: '#ffffff', fontSize: '22px'}}>&nbsp;&nbsp;rocketIQ</span>
                            </a>
                        </div>
                        <div className="card-body">
                            <p className="text-center py-2">RECOVER YOU PASSWORD</p>
                            <form className="mb-3" name="formRecover" onSubmit={this.onSubmit}>
                                <p className="text-center">Please provide your email to receive instructions on how to recover your password.</p>
                                <div className="form-group">
                                    <div className="input-group with-focus">
                                        <Input
                                            type="email"
                                            name="email"
                                            className="border-right-0"
                                            placeholder="Enter email"
                                            invalid={this.hasError('formRecover', 'email', 'required') || this.hasError('formRecover', 'email', 'email')}
                                            onChange={this.validateOnChange}
                                            data-validate='["required", "email"]'
                                            value={this.state.formRecover.email}/>
                                        <div className="input-group-append">
                                            <span className="input-group-text text-muted bg-transparent border-left-0">
                                                <em className="fa fa-envelope"></em>
                                            </span>
                                        </div>
                                        {this.hasError('formRecover', 'email', 'required')
                                        && <span className="invalid-feedback">Field is required</span>}
                                        {this.hasError('formRecover', 'email', 'email')
                                        && <span className="invalid-feedback">Field must be valid email</span>}
                                    </div>
                                </div>
                                <button className="btn btn-purple btn-block" type="submit">Reset</button>
                                <div className="clearfix mt-3">
                                    <div className="text-center">
                                        <Link to="login" className="text-muted">Back to Login</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    {/* END card */}
                    <div className="p-3 text-center">
                        {getCopyright()}
                    </div>
                </div>
            </div>
        );
    }
}