/**
 * Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */


'use strict';

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withNamespaces, Trans} from 'react-i18next';
import {Link, withRouter} from 'react-router-dom';
import {Collapse, Badge} from 'reactstrap';
import SidebarRun from './Sidebar.run';

import AdminMenu from '../../../admin/AdminMenu';
import OrganizationMenu from '../../../organization/OrganizationMenu';

import {getVersion} from '../../utils';
import AccountStore from '../../stores/AccountStore';

/** Component to display headings on sidebar */
const SidebarItemHeader = ({item}) => {
    if (item.heading === 'MAIN-NAV-HEADING') {
        return (
            <li className="nav-heading">
                <span>{`${getVersion(true)}`}</span>
            </li>
        );
    }
    return (
        <li className="nav-heading">
            <span><Trans i18nKey={item.translate}>{item.heading}</Trans></span>
        </li>
    );
};

SidebarItemHeader.propTypes = {
    item: PropTypes.object.isRequired
};

/** Normal items for the sidebar */
const SidebarItem = ({item, isActive}) => (
    <li className={isActive ? 'active' : ''}>
        <Link to={item.path} title={item.name}>
            {item.label && <Badge tag="div" className="float-right" color={item.label.color}>{item.label.value}</Badge>}
            {item.icon && <em className={item.icon}></em>}
            <span><Trans i18nKey={item.translate}>{item.name}</Trans></span>
        </Link>
    </li>
);

SidebarItem.propTypes = {
    item: PropTypes.object.isRequired,
    isActive: PropTypes.bool
};

/** Build a sub menu with items inside and attach collapse behavior */
const SidebarSubItem = (
    {
        item,
        isActive,
        handler,
        children,
        isOpen
    }
) => (
    <li className={isActive ? 'active' : ''}>
        <div className="nav-item" onClick={handler}>
            {item.label && <Badge tag="div" className="float-right" color={item.label.color}>{item.label.value}</Badge>}
            {item.icon && <em className={item.icon}></em>}
            <span><Trans i18nKey={item.translate}>{item.name}</Trans></span>
        </div>
        <Collapse isOpen={isOpen}>
            <ul id={item.path} className="sidebar-nav sidebar-subnav">
                {children}
            </ul>
        </Collapse>
    </li>
);

SidebarSubItem.propTypes = {
    item: PropTypes.object.isRequired,
    isActive: PropTypes.bool,
    handler: PropTypes.func,
    children: PropTypes.array,
    isOpen: PropTypes.bool
};

/** Component used to display a header on menu when using collapsed/hover mode */
const SidebarSubHeader = ({item}) => (
    <li className="sidebar-subnav-header">{item.name}</li>
);

SidebarSubHeader.propTypes = {
    item: PropTypes.object.isRequired
};

class Sidebar extends Component {
    state = {
        collapse: {},
        accountStore: AccountStore.getState()
    };

    componentDidMount() {
        // pass navigator to access router api
        SidebarRun(this.navigator.bind(this));
        // prepare the flags to handle menu collapsed states
        if (this.state.accountStore.roleMapping === 'ADMIN') this.buildCollapseList(AdminMenu);
        if (this.state.accountStore.roleMapping === 'ORGANIZATION') this.buildCollapseList(OrganizationMenu);
        AccountStore.listen(this.onChange);
    }

    componentWillUnmount() {
        AccountStore.unlisten(this.onChange);
    }

    onChange = state => this.setState({
        accountStore: state
    });

    /** prepare initial state of collapse menus. Doesnt allow same route names */
    buildCollapseList = (menu) => {
        const collapse = {};
        menu
            .filter(({heading}) => !heading)
            .forEach(({name, path, submenu}) => {
                collapse[name] = this.routeActive(submenu ? submenu.map(({path}) => path) : path); // eslint-disable-line no-shadow
            });
        this.setState({collapse});
    };

    navigator(route) {
        // For whatever reason this does not work as expected because the route is being passed in with the
        // leading # (hash) - probably due to the render of the link and the way SidebarRun; grabs the
        // href from the anchor element (generated by React)
        // strip it out
        // Note this will need to change if ReactRouter defaults (eg hash type) on HashRouter are changed
        return this.props.history.push(route.replace('#/', '/'));
    }

    routeActive(paths) {
        paths = Array.isArray(paths) ? paths : [paths]; // eslint-disable-line no-param-reassign
        return paths.some(p => this.props.location.pathname.indexOf(p) > -1);
    }

    /* eslint-disable */
    toggleItemCollapse(stateName) {
        for (let c in this.state.collapse) {
            if (this.state.collapse[c] === true && c !== stateName)
                this.setState({
                    collapse: {
                        [c]: false
                    }
                });
        }
        this.setState({
            collapse: {
                [stateName]: !this.state.collapse[stateName]
            }
        });
    }
    /* eslint-enable */

    getSubRoutes = item => item.submenu.map(({path}) => path);

    /** map menu config to string to determine what elemetn to render */
    itemType = (item) => { // eslint-disable-line consistent-return
        if (item.heading) return 'heading';
        if (!item.submenu) return 'menu';
        if (item.submenu) return 'submenu';
    };

    render() {
        // not particularly elegant
        const menu = this.state.accountStore.roleMapping === 'ADMIN' ? AdminMenu : OrganizationMenu;
        return (
            <aside className='aside-container'>
                {/* START Sidebar (left) */}
                <div className="aside-inner">
                    <nav data-sidebar-anyclick-close="" className="sidebar">
                        {/* START sidebar nav */}
                        <ul className="sidebar-nav">
                            {/* Iterates over all sidebar items */}
                            {
                                menu.map((item, i) => {
                                    // heading
                                    if (this.itemType(item) === 'heading') {
                                        return (<SidebarItemHeader item={item} key={i}/>);
                                    }
                                    if (this.itemType(item) === 'menu') {
                                        return (<SidebarItem isActive={this.routeActive(item.path)} item={item} key={i}/>);
                                    }
                                    if (this.itemType(item) === 'submenu') {
                                        return [
                                            <SidebarSubItem
                                                item={item}
                                                isOpen={this.state.collapse[item.name]}
                                                handler={this.toggleItemCollapse.bind(this, item.name)}
                                                isActive={this.routeActive(this.getSubRoutes(item))}
                                                key={i}>
                                                <SidebarSubHeader item={item} key={i}/>
                                                {
                                                    // eslint-disable-next-line no-shadow
                                                    item.submenu.map((subitem, i) => <SidebarItem key={i} item={subitem} isActive={this.routeActive(subitem.path)}/>)
                                                }
                                            </SidebarSubItem>
                                        ];
                                    }
                                    return null; // unrecognized item
                                })
                            }
                        </ul>
                        {/* END sidebar nav */}
                    </nav>
                </div>
                {/* END Sidebar (left) */}
            </aside>
        );
    }
}

Sidebar.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object
};

export default withNamespaces('translations')(withRouter(Sidebar));
