/**
 * Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

import alt from '../../../alt';

import AccountActions from '../actions/AccountActions';
import {getRoleMapping, isOwner, getName} from '../services/AccountService';

class AccountStore {
    constructor(actions) {
        this.state = _getInitState();
        this.bindActions(actions);
    }

    login(account) {
        this.setState({
            authenticated: true,
            initialized: true,
            account: account,
            name: getName(account.profile),
            roleMapping: getRoleMapping(account),
            isOwner: isOwner(account),
        });
    }

    logout() {
        this.setState(_getInitState());
    }
}

function _getInitState() {
    return {
        authenticated: false,
        initialized: false,
        account: null,
        name: null,
        roleMapping: null,
        isOwner: false,
    };
}

export default alt.createStore(AccountStore, 'AccountStore', AccountActions);
