/**
 * Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Alert,
    Card,
    CardBody,
    FormGroup,
    Input,
} from 'reactstrap';
import ContentWrapper from '../../../../common/components/layout/ContentWrapper';
import FormValidator from '../../../../common/components/forms/FormValidator';
import SpinnerOverlay from '../../../../common/components/SpinnerOverlay';

import TeamActions from './TeamActions';
import {createTeamMember, getTeam} from './TeamService';

export default class CreateTeamMember extends Component {
    static propTypes = {
        history: PropTypes.object
    };

    static defaultProps = {};

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            alertSuccess: null,
            alertFailure: null,
            formCreateTeamMember: {
                email: '',
                firstName: '',
                lastName: ''
            }
        };
    }

    // This likely can be pushed to a utility class (or a parent form class)
    validateOnChange = (event) => {
        const input = event.target;
        const form = input.form; // eslint-disable-line prefer-destructuring
        const value = input.type === 'checkbox' ? input.checked : input.value;

        const result = FormValidator.validate(input);

        this.setState({
            [form.name]: {
                ...this.state[form.name],
                [input.name]: value,
                errors: {
                    ...this.state[form.name].errors,
                    [input.name]: result
                }
            }
        });
    };

    onSubmit = (e) => {
        e.preventDefault();
        const form = e.target;
        // This may need to be updated based on the controls (inputs, selects, etc) in your form
        const inputs = [...form.elements].filter(i => ['INPUT'].includes(i.nodeName));
        const {errors, hasError} = FormValidator.bulkValidate(inputs);

        if (hasError) {
            this.setState({
                [form.name]: {
                    ...this.state[form.name],
                    errors
                }
            });
        } else {
            this.setState({
                loading: true,
                [form.name]: {
                    ...this.state[form.name],
                    errors
                }
            }, () => {
                createTeamMember(this.state.formCreateTeamMember.email, this.state.formCreateTeamMember.firstName, this.state.formCreateTeamMember.lastName)
                    .then((teamMember) => { // eslint-disable-line
                        this.setState({
                            loading: false,
                            alertSuccess: `Team Member - ${this.state.formCreateTeamMember.firstName} ${this.state.formCreateTeamMember.lastName} - created. Instructions to finish account setup have been sent to ${this.state.formCreateTeamMember.email}`, // eslint-disable-line
                            // Reset form state
                            formCreateTeamMember: {
                                email: '', // This doesn't seem to be taking hold ....
                                firstName: '',
                                lastName: ''
                            }
                        }, () => {
                            getTeam()
                                .then((team) => {
                                    // this.props.history.push(URLS.organizations.team.base);
                                    TeamActions.init(team);
                                })
                                .catch((error) => {
                                    console.error(error);
                                });
                        });
                    })
                    .catch(error => { // eslint-disable-line
                        // console.log(error.response.body.error.message);
                        this.setState({
                            loading: false,
                            alertFailure: `There was a problem - ${error.response.body.error.message}`
                        });
                    });
            });
        }
    };

    /* Simplify error check */
    hasError = (formName, inputName, method) => this.state[formName]
        && this.state[formName].errors
        && this.state[formName].errors[inputName]
        && this.state[formName].errors[inputName][method];

    render() {
        return (
            <ContentWrapper>
                {this.state.loading && <SpinnerOverlay/>}
                <div className="content-heading">
                    <div>Team - Create Team Member
                        <small className="mt-1">provide an email, first and  last name. Select create. We&#39;ll take care of the rest</small>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Card className="card-default">
                            <CardBody>
                                <form name="formCreateTeamMember" onSubmit={this.onSubmit}>
                                    <FormGroup>
                                        <label>Email</label>
                                        <Input
                                            type="text"
                                            name="email"
                                            placeholder="Enter Email"
                                            invalid={this.hasError('formCreateTeamMember', 'email', 'required') || this.hasError('formCreateTeamMember', 'email', 'email')}
                                            onChange={this.validateOnChange}
                                            data-validate='["required", "email"]'
                                            value={this.state.formCreateTeamMember.email}
                                        />
                                        {this.hasError('formCreateTeamMember', 'email', 'required') && <span className="invalid-feedback">Field is required</span>}
                                        {this.hasError('formCreateTeamMember', 'email', 'email') && <span className="invalid-feedback">Invalid email</span>}
                                    </FormGroup>
                                    <FormGroup>
                                        <label>First Name</label>
                                        <Input
                                            type="text"
                                            name="firstName"
                                            placeholder="Enter First Name"
                                            invalid={this.hasError('formCreateTeamMember', 'firstName', 'required')}
                                            onChange={this.validateOnChange}
                                            data-validate='["required"]'
                                            value={this.state.formCreateTeamMember.firstName}
                                        />
                                        {this.hasError('formCreateTeamMember', 'firstName', 'required')
                                        && <span className="invalid-feedback">Field is required</span>}
                                    </FormGroup>
                                    <FormGroup>
                                        <label>Last Name</label>
                                        <Input
                                            type="text"
                                            name="lastName"
                                            placeholder="Enter Last Name"
                                            invalid={this.hasError('formCreateTeamMember', 'lastName', 'required')}
                                            onChange={this.validateOnChange}
                                            data-validate='["required"]'
                                            value={this.state.formCreateTeamMember.lastName}
                                        />
                                        {this.hasError('formCreateTeamMember', 'lastName', 'required')
                                        && <span className="invalid-feedback">Field is required</span>}
                                    </FormGroup>
                                    {this.state.alertSuccess && (
                                        <Alert color="success">{this.state.alertSuccess}</Alert>
                                    )}
                                    {this.state.alertFailure && (
                                        <Alert color="danger">{this.state.alertFailure}</Alert>
                                    )}
                                    <div className="text-right">
                                        <button className="btn btn-green text-thin" type="submit">Create</button>
                                    </div>
                                </form>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}
