const OrganizationMenu = [
    {
        heading: 'MAIN-NAV-HEADING',
        translate: 'org.sidebar.heading.HEADER'
    },
    {
        name: 'Dashboard',
        path: '/org/dashboard',
        icon: 'icon-speedometer',
        translate: 'org.sidebar.nav.DASHBOARD'
    },
    {
        name: 'Assets',
        path: '/org/assets',
        icon: 'icon-layers',
        translate: 'org.sidebar.nav.ASSETS'
    },
    {
        name: 'Explore',
        path: '/org/explore',
        icon: 'icon-compass',
        translate: 'org.sidebar.nav.EXPLORE'
    },
    {
        name: 'Reports',
        path: '/org/reports',
        icon: 'icon-chart',
        translate: 'org.sidebar.nav.REPORTS'
    },
    {
        name: 'Tools',
        icon: 'icon-wrench',
        translate: 'org.sidebar.nav.TOOLS',
        submenu: [
            {
                name: 'Load',
                translate: 'org.sidebar.nav.tools.LOAD',
                path: '/org/tools/load'
            },
            {
                name: 'Match',
                translate: 'org.sidebar.nav.tools.MATCH',
                path: '/org/tools/match'
            }
        ]
    },
    {
        name: 'Manage',
        icon: 'icon-grid',
        translate: 'org.sidebar.nav.MANAGE',
        submenu: [
            {
                name: 'Applications',
                translate: 'org.sidebar.nav.manage.APPLICATIONS',
                path: '/org/manage/applications'
            },
            {
                name: 'Plan',
                translate: 'org.sidebar.nav.manage.PLAN',
                path: '/org/manage/plan'
            },
            {
                name: 'Team',
                translate: 'org.sidebar.nav.manage.TEAM',
                path: '/org/manage/team'
            },
        ]
    }
];

export default OrganizationMenu;
