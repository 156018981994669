/**
 * Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Row,
    Col,
    CardHeader,
    CardBody,
    Card,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Table
} from 'reactstrap';

import ContentWrapper from '../../components/layout/ContentWrapper';
import {APP_COLORS} from '../../constants';
import {isOdd, isEven} from '../../utils';
import TaxonomySunburst from '../../components/charts/TaxonomySunburst.jsx';

export default class Product extends Component {
    static propTypes = {
        history: PropTypes.object,
        product: PropTypes.object
    };

    static defaultProps = {};

    constructor(props) { // eslint-disable-line no-useless-constructor
        super(props);
        this.state = {
            dropdownOpen: false,
            viewAsJson: false
        };
    }

    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    };

    render() {
        const product = this.props.product; // eslint-disable-line
        return (
            <ContentWrapper>
                {product && (
                    <div>
                        <Row>
                            {/* FIRST COLUMN */}
                            <Col lg={9} md={8}>
                                <Card className="card-default">
                                    <CardHeader>
                                        {/* Nested inner column (for main product card) */}
                                        <Row>
                                            <Col lg={9} md={8}>
                                                <small className="text-muted">{product.hid}</small>
                                                <h4>{`${product.brand.name} ${product.modelNumber}`}
                                                    <br/>
                                                    <p className="pt-1 text-thin text-sm"><em>{product.description}</em></p>
                                                </h4>
                                            </Col>
                                            {/* Images (Action, buttons similar if wanted) */}
                                            <Col lg={3} md={4}>
                                                <div className="ml-auto fa-pull-right">
                                                    <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                                                        <DropdownToggle color="link" style={{
                                                            color: APP_COLORS['gray-dark']
                                                        }}>
                                                            <em className="fa fa-ellipsis-h fa-lg"/>
                                                        </DropdownToggle>
                                                        <DropdownMenu className="dropdown-menu-right-forced">
                                                            {!this.state.viewAsJson && (
                                                                <DropdownItem
                                                                    onClick={() => this.setState({viewAsJson: true})}
                                                                >
                                                                    <em className="fa-fw fa fa-code mr-2"/>
                                                                    <span>View Raw</span>
                                                                </DropdownItem>
                                                            )}
                                                            {this.state.viewAsJson && (
                                                                <DropdownItem
                                                                    onClick={() => this.setState({viewAsJson: false})}
                                                                >
                                                                    <em className="fa-fw fa fa-code mr-2"/>
                                                                    <span>View Product</span>
                                                                </DropdownItem>
                                                            )}
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    {!this.state.viewAsJson && (
                                        <CardBody>
                                            {/* Nested inner column (for main product card) */}
                                            <Row>
                                                {/* Product Overview and Features */}
                                                <Col lg={9} md={8}>
                                                    <h4 className="text-thin">Overview</h4>
                                                    <hr className="mt-2"/>
                                                    <p>{product.overview}</p>
                                                    <ul>
                                                        {_.values(product.features).map((f, i) => (
                                                            <li key={i}>{f}</li>
                                                        ))}
                                                    </ul>
                                                </Col>
                                                {/* Images nad Media */}
                                                {/* Offset the top with negative margin to creep back up into the header */}
                                                <Col lg={3} md={4} style={{
                                                    marginTop: '-70px'
                                                }}>
                                                    {/* Hero Image Row */}
                                                    <Row>
                                                        <Col lg={12} md={12}>
                                                            <div className="mb-2 text-center">
                                                                <img className="img-fluid rounded" src="img/sample/rheem-natural-gas-water-heaters-xg40t06ec36u1-64_1000.jpg" alt="Dummy" />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    {/* Thumbnails */}
                                                    <Row>
                                                        <Col lg={12} md={12}>
                                                            <div className="mb-2" style={{
                                                                overflowX: 'auto',
                                                                whiteSpace: 'nowrap'
                                                            }}>
                                                                <img className="img-fluid rounded thumb64" src="img/placeholder/placeholder-image.png" alt="Dummy" />
                                                                <img className="ml-1 img-fluid rounded thumb64" src="img/placeholder/placeholder-image.png" alt="Dummy" />
                                                                <img className="ml-1 img-fluid rounded thumb64" src="img/placeholder/placeholder-image.png" alt="Dummy" />
                                                                <img className="ml-1 img-fluid rounded thumb64" src="img/placeholder/placeholder-image.png" alt="Dummy" />
                                                                <img className="ml-1 img-fluid rounded thumb64" src="img/placeholder/placeholder-image.png" alt="Dummy" />
                                                                <img className="ml-1 img-fluid rounded thumb64" src="img/placeholder/placeholder-image.png" alt="Dummy" />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    {/* Media */}
                                                    <Row>
                                                        <Col lg={12} md={12}>
                                                            <div className="mb-2 text-center">
                                                                <img className="img-fluid rounded" src="img/placeholder/placeholder-video.png" alt="Dummy" />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            {/* Product Dimensions */}
                                            <Row>
                                                <Col lg={12} md={12}>
                                                    <h4 className="text-thin">Dimensions</h4>
                                                    <hr className="mt-2"/>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={6} md={6}>
                                                    <Table className="mb-3" style={{
                                                        width: '100%'
                                                    }}>
                                                        <tbody>
                                                            {_.filter(product.dimensions, (v, i) => isEven(i)).map(d => (<ProductDetailOrDimension
                                                                key={d.key}
                                                                data={d}
                                                            />))}
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                                <Col lg={6} md={6}>
                                                    <Table className="mb-3" style={{
                                                        width: '100%'
                                                    }}>
                                                        <tbody>
                                                            {_.filter(product.dimensions, (v, i) => isOdd(i)).map(d => (<ProductDetailOrDimension
                                                                key={d.key}
                                                                data={d}
                                                            />))}
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            </Row>
                                            {/* Product Details */}
                                            <Row>
                                                <Col lg={12} md={12}>
                                                    <h4 className="text-thin">Details</h4>
                                                    <hr className="mt-2"/>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={6} md={6}>
                                                    <Table className="mb-3" style={{
                                                        width: '100%'
                                                    }}>
                                                        <tbody>
                                                            {_.filter(product.details, (v, i) => isEven(i)).map(d => (<ProductDetailOrDimension
                                                                key={d.key}
                                                                data={d}
                                                            />))}
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                                <Col lg={6} md={6}>
                                                    <Table className="mb-3" style={{
                                                        width: '100%'
                                                    }}>
                                                        <tbody>
                                                            {_.filter(product.details, (v, i) => isOdd(i)).map(d => (<ProductDetailOrDimension
                                                                key={d.key}
                                                                data={d}
                                                            />))}
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            </Row>
                                            {/* Product Certifications and Warranties */}
                                            <Row>
                                                <Col lg={12} md={12}>
                                                    <h4 className="text-thin">Certifications and Warranties</h4>
                                                    <hr className="mt-2"/>
                                                    <Table className="mb-3" style={{
                                                        width: '100%'
                                                    }}>
                                                        <tbody>
                                                            {product.certifications.map(c => (<ProductCertificationOrWarranty
                                                                key={c.key}
                                                                cert
                                                                data={c}
                                                            />))}
                                                            {product.warranties.map(w => (<ProductCertificationOrWarranty
                                                                key={w.key}
                                                                warn
                                                                data={w}
                                                            />))}
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            </Row>
                                            {/* Product Documents */}
                                            <Row>
                                                <Col lg={12} md={12}>
                                                    <h4 className="text-thin">Documents</h4>
                                                    <hr className="mt-2"/>
                                                    <p className="text-muted text-center">DOCUMENTS NOT AVAILABLE</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={12} md={12}>
                                                    <small className="text-muted"><em>hid: {product.hid}</em></small>
                                                    <small className="text-muted fa-pull-right"><em>last updated {product.updated}</em></small>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    )}
                                    {this.state.viewAsJson && (
                                        <CardBody>
                                            <Row>
                                                <Col lg={12} md={12}>
                                                    <div>
                                                        <pre style={{
                                                            // color: APP_COLORS['gray-darker'],
                                                            overflow: 'auto',
                                                            // whiteSpace: 'pre-wrap'
                                                        }}>{JSON.stringify(this.props.product, undefined, 2)}</pre>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={12} md={12}>
                                                    <small className="text-muted"><em>hid: {product.hid}</em></small>
                                                    <small className="text-muted fa-pull-right"><em>last updated {product.updated}</em></small>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    )}
                                </Card>
                            </Col>
                            {/* SECOND COLUMN */}
                            <Col lg={3} md={4}>
                                <Card className="card-default">
                                    <Row>
                                        <Col lg={12} md={12}>
                                            <CardHeader>
                                                <span className="text-muted"><em>Taxonomy</em></span>
                                            </CardHeader>
                                        </Col>
                                        <Col lg={12} md={12}>
                                            <CardBody>
                                                <TaxonomySunburst/>
                                            </CardBody>
                                        </Col>
                                    </Row>
                                </Card>
                                <Card className="card-default">
                                    <CardHeader>
                                        <pre>Prices</pre>
                                    </CardHeader>
                                    <CardBody>
                                    </CardBody>
                                </Card>
                                <Card className="card-default">
                                    <CardHeader>
                                        <pre>Analyses</pre>
                                    </CardHeader>
                                    <CardBody>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                )}
            </ContentWrapper>
        );
    }
}

const ProductDetailOrDimension = (props) => {
    const _style = {
        width: '50%',
        borderTop: '1.5px dashed',
        borderBottom: '1.5px dashed',
        borderColor: APP_COLORS.gray,
    };
    const value = props.data.unitOfMeasure ? `${props.data.value} ${props.data.unitOfMeasure}` : props.data.value;
    return (
        <tr>
            <td style={_.assign({}, _style, {backgroundColor: APP_COLORS['gray-lighter']})}>
                <strong>{props.data.display}</strong>
            </td>
            <td style={_style}>
                {value}
            </td>
        </tr>
    );
};

ProductDetailOrDimension.propTypes = {
    data: PropTypes.object,
};

const ProductCertificationOrWarranty = (props) => {
    const _style = {
        width: '50%',
        borderTop: '1.5px dashed',
        borderBottom: '1.5px dashed',
        borderColor: APP_COLORS.gray,
    };
    return (
        <tr>
            <td style={_.assign({}, _style, {backgroundColor: APP_COLORS['gray-lighter']})}>
                <strong>{props.data.value}</strong>
            </td>
            <td style={_style}>
                {props.cert && (
                    <a href="">certified on: {props.data.details.dateCertified ? props.data.details.dateCertified : 'N/A'}</a>
                )}
                {props.warn && (
                    <span>{`${props.data.details.duration} (${props.data.details.type})`}</span>
                )}
            </td>
        </tr>
    );
};

ProductCertificationOrWarranty.propTypes = {
    data: PropTypes.object,
    cert: PropTypes.boolean,
    warn: PropTypes.boolean,
};
