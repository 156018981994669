/**
 * Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

import _ from 'lodash';

import alt from '../../../../../alt';

import TeamActions from './TeamActions';

class TeamStore {
    constructor(actions) {
        this.state = _getInitState();
        this.bindActions(actions);
    }

    init(team) {
        this.setState({
            initialized: true,
            team: team,
        });
    }

    updateTeamMember(teamMember) {
        // TODO: This is pretty volatile; a work around for not having an individual getter on the backend
        if (!teamMember.hid) throw new Error('Invalid invocation, teamMember is missing hid attribute');
        const {team} = this.state;
        const localTeamMember = _.find(team, {hid: teamMember.hid});
        const udpatedTeamMember = _.assign({}, localTeamMember, teamMember);
        team.splice(_.findIndex(team, {hid: teamMember.hid}), 1, udpatedTeamMember);
        this.setState({
            team: team
        });
    }
}

function _getInitState() {
    return {
        initialized: false,
        team: [],
    };
}

export default alt.createStore(TeamStore, 'TeamStore', TeamActions);
