/**
 * Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

import alt from '../../../../alt';

import DashboardActions from './DashboardActions';

class DashboardStore {
    constructor(actions) {
        this.state = _getInitState();
        this.bindActions(actions);
    }

    init(data) {
        this.setState({
            initialized: true,
            usageStatistics: data.stats,
            recentUsage: data.recent
        });
    }
}

function _getInitState() {
    return {
        initialized: false,
        usageStatistics: null,
    };
}

export default alt.createStore(DashboardStore, 'DashboardStore', DashboardActions);
