/**
 * Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import {FadingCircle} from 'better-react-spinkit';
import {APP_COLORS} from '../constants';

const Spinner = props => (
    <FadingCircle
        size={props.size}
        color={props.color}
    />
);


Spinner.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string
};

Spinner.defaultProps = {
    size: 50,
    color: APP_COLORS.purple
};

export default Spinner;
