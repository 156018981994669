/**
 * @copyright Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

import React, {Component} from 'react'; // eslint-disable-line no-unused-vars

import Dashboard from './Dashboard.jsx';
import DashboardStore from './DashboardStore';
import {initializeDashboard} from './DashboardService';
import DashboardActions from './DashboardActions';
import SpinnerOverlay from '../../../common/components/SpinnerOverlay';

/**
 * DashboardContainer
 */
export default class DashboardContainer extends Component {
    static propTypes = {};

    static defaultProps = {};

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            dashboardStore: DashboardStore.getState()
        };

        this.onChange = state => this.setState({dashboardStore: state});
    }

    componentDidMount() {
        DashboardStore.listen(this.onChange);
        if (!this.state.dashboardStore.initialized) {
            this.setState({
                loading: true
            }, () => {
                initializeDashboard()
                    .then((data) => {
                        this.setState({
                            loading: false
                        }, () => DashboardActions.init(data));
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            });
        }
    }

    componentWillUnmount() {
        DashboardStore.unlisten(this.onChange);
    }

    render() { // eslint-disable-line
        return (
            <div>
                {this.state.loading && <SpinnerOverlay/>}
                <Dashboard
                    {...this.props}
                    usageStatistics={this.state.dashboardStore.usageStatistics}
                    recentUsage={this.state.dashboardStore.recentUsage}
                />
            </div>
        );
    }
}
