/**
 * Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

/* eslint-disable */

import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Alert,
    Button,
    Card,
    CardHeader,
    CardBody,
    Collapse,
    Col,
    FormGroup,
    Input,
    Row,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import ContentWrapper from '../../../common/components/layout/ContentWrapper';
import FormValidator from '../../../common/components/forms/FormValidator';
import SpinnerOverlay from '../../../common/components/SpinnerOverlay';
import Dropzone from 'react-dropzone';

import ApplicationsActions from './AssetsActions';
import Progress from '../../../common/components/Progress';
import {createAsset} from './AssetsService';
import {APP_COLORS, URLS} from '../../../common/constants';
import {isEven, isOdd} from "../../../common/utils";

import kaRange from '../../../common/data/KitchenAid-Range-KSDB900ES.json';
import kaRefer from '../../../common/data/KitchenAid-Refrigerator-KRFC302ESS';

export default class CreateAsset extends Component {
    static propTypes = {
        history: PropTypes.object
    };

    static defaultProps = {};

    constructor(props) {
        super(props);
        this.state = {
            filesToMatch: [],
            matchStatus: null,
            matchedFiles: [],

            dropdownOpen: false,

            loading: false,
            matching: false,
            alertSuccess: null,
            alertFailure: null,

            formCreateApplication: {
                // 'name' is a reserved keyword using the angle framework apparently (and will bork your shit)
                iname: '',
                description: ''
            }
        };
    }

    onFileDrop = (files) => {
        console.log(files);
        if (files.length > 1) {
            throw new Error('Multiple files not supported')
        }

        if (files.length === 1) {
            const reader = new FileReader();
            reader.onload = () => {
                const readAsDataURL = reader.result;
                // do whatever you want with the file content
                const _filesToMatch = [];
                _filesToMatch.push(_.assign(files[0], {
                    dataUrl: readAsDataURL
                }));
                this.setState({
                    loading: false,
                    filesToMatch: _filesToMatch,
                    matchStatus: {
                        message: 'Calibrating Systems'
                    }
                }, () => {
                    setTimeout(() => this.setState({matchStatus: {message: 'Preparing to Launch'}}, () => {
                        setTimeout(() => this.setState({matchStatus: {message: 'Computing'}}, () => {
                            setTimeout(() => this.setState({matchStatus: {message: 'Annotating'}}, () => {
                                setTimeout(() => this.setState({matchStatus: {message: 'Matching'}}, () => {
                                    setTimeout(() => this.setState({
                                        matchStatus: {message: 'Matched! - KitchenAid KSDB900ESS'}, // Should be subbed from the match
                                        matchedFiles: _filesToMatch
                                    }, () => {
                                        // End State
                                        setTimeout(() => this.setState({
                                            filesToMatch: []
                                        }), 2000)
                                    }), 2000)
                                }), 2000)
                            }), 2000)
                        }), 2000)
                    }), 2000)
                })
            };
            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');
            reader.readAsDataURL(files[0]);
        }
    };

    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    };

    // This likely can be pushed to a utility class (or a parent form class)
    validateOnChange = (event) => {
        const input = event.target;
        const form = input.form; // eslint-disable-line prefer-destructuring
        const value = input.type === 'checkbox' ? input.checked : input.value;

        const result = FormValidator.validate(input);

        this.setState({
            [form.name]: {
                ...this.state[form.name],
                [input.name]: value,
                errors: {
                    ...this.state[form.name].errors,
                    [input.name]: result
                }
            }
        });
    };

    onSubmit = (e) => {
        e.preventDefault();
        const form = e.target;
        // This may need to be updated based on the controls (inputs, selects, etc) in your form
        const inputs = [...form.elements].filter(i => ['INPUT', 'TEXTAREA'].includes(i.nodeName));
        const {errors, hasError} = FormValidator.bulkValidate(inputs);

        if (hasError) {
            this.setState({
                [form.name]: {
                    ...this.state[form.name],
                    errors
                }
            });
        } else {
            this.setState({
                loading: true,
                [form.name]: {
                    ...this.state[form.name],
                    errors
                }
            }, () => {
                createAsset(this.state.formCreateApplication.iname, this.state.formCreateApplication.description)
                    .then((application) => {
                        this.setState({
                            loading: false,
                            alertSuccess: `Application - ${application.name} - created and assigned key - ${application.key}`,
                            // Reset form state
                            formCreateApplication: {
                                iname: '', // This doesn't seem to be taking hold ....
                                description: ''
                            }
                        }, () => {
                            this.props.history.push(URLS.organizations.applications.base);
                            ApplicationsActions.create(application);
                        });
                    })
                    .catch(error => { // eslint-disable-line
                        this.setState({
                            loading: false,
                            alertFailure: `There was a problem - ${error.message}`
                        });
                    });
            });
        }
    };

    /* Simplify error check */
    hasError = (formName, inputName, method) => this.state[formName]
        && this.state[formName].errors
        && this.state[formName].errors[inputName]
        && this.state[formName].errors[inputName][method];

    render() {
        // console.log(this.state);
        const product = kaRange;
        return (
            <ContentWrapper>
                {this.state.loading && <SpinnerOverlay/>}
                <div className="content-heading">
                    <div>
                        Assets - Create
                        <small>create a new asset</small>
                    </div>
                </div>
                <Row>
                    <Col xl={12} lg={12} md={12}>
                        <Card className="card-default">
                            <CardHeader>
                                <span className="lead text-muted">Match</span>
                                <span className="text-muted"><em> upload an image to automatically identify and pre-populate asset data</em></span>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col xl={9} lg={9} md={9}>

                                    </Col>
                                    <Col xl={3} lg={3} md={3}>
                                        {this.state.filesToMatch.length === 0 && this.state.matchedFiles.length === 0 && (
                                            <Dropzone
                                                className="p-1"
                                                ref="dropzone"
                                                onDrop={this.onFileDrop}
                                                multiple={false}
                                            >
                                                <div className="box-placeholder mb-0">
                                                    <Row>
                                                        <Col lg={3} md={3}>
                                                            <div>
                                                                <img className="img-fluid rounded"
                                                                     style={{height: 'auto', width: '200px'}}
                                                                     src="img/placeholder/placeholder-image.png"
                                                                     alt="Dummy"/>
                                                            </div>
                                                        </Col>
                                                        <Col xl={{size: 5, offset: 1}} lg={{size: 5, offset: 1}} md={{size: 9}} style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center'
                                                        }}>
                                                            <p className="lead text-muted text-center">Drag an image of your asset here<br/>(a photo of the <span className="text-bold text-purple">Asset</span> or the <span className="text-bold text-purple">Nameplate</span>)<br/>or select one from your computer</p>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Dropzone>
                                        )}
                                        {this.state.filesToMatch.length === 1 && this.state.matchedFiles.length === 0 && (
                                            <div className="box-placeholder mb-0">
                                                <Row>
                                                    <Col lg={3} md={3}>
                                                        <div>
                                                            <img className="img-fluid rounded"
                                                                 style={{height: 'auto', width: '200px'}}
                                                                 src={this.state.filesToMatch[0].dataUrl}
                                                                 alt="Image to Match"/>
                                                        </div>
                                                    </Col>
                                                    <Col xl={{size: 1}} lg={{size: 1}} md={{size: 1}} style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}>
                                                        <Progress/>
                                                    </Col>
                                                    <Col xl={{size: 5}} lg={{size: 5}} md={{size: 9}} style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}>
                                                        <p className="h3 text-muted text-center">{this.state.matchStatus.message}</p>
                                                    </Col>
                                                </Row>
                                            </div>
                                        )}
                                        {this.state.filesToMatch.length === 1 && this.state.matchedFiles.length === 1 && (
                                            <div className="box-placeholder mb-0">
                                                <Row>
                                                    <Col lg={3} md={3}>
                                                        <div>
                                                            <img className="img-fluid rounded"
                                                                 style={{height: 'auto', width: '200px'}}
                                                                 src={this.state.filesToMatch[0].dataUrl}
                                                                 alt="Matched Image"/>
                                                        </div>
                                                    </Col>
                                                    <Col xl={{size: 1}} lg={{size: 1}} md={{size: 1}} style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}>
                                                        <em className="text-green icon-check fa-5x"/>
                                                    </Col>
                                                    <Col xl={{size: 5}} lg={{size: 5}} md={{size: 9}} style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}>
                                                        <p className="h3 text-muted text-center">{this.state.matchStatus.message}</p>
                                                    </Col>
                                                </Row>
                                            </div>
                                        )}
                                        {this.state.filesToMatch.length === 0 && this.state.matchedFiles.length === 1 && (
                                            <div className="box-placeholder mb-0">
                                                <Row>
                                                    <Col lg={3} md={3}>
                                                        <div>
                                                            <img className="img-fluid rounded"
                                                                 style={{height: 'auto', width: '200px'}}
                                                                 src={this.state.matchedFiles[0].dataUrl}
                                                                 alt="Matched Image"/>
                                                        </div>
                                                    </Col>
                                                    <Col xl={{size: 1}} lg={{size: 1}} md={{size: 1}} style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}>
                                                    </Col>
                                                    <Col xl={{size: 5}} lg={{size: 5}} md={{size: 9}} style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}>
                                                        <p className="h3 text-muted text-center">Blank Canvas</p>
                                                    </Col>
                                                </Row>
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <div>
                    <Row>
                        <Col lg={12} md={12}>
                            <Card className="card-default">
                                {/* Card Header - Manage Product Association */}
                                <CardHeader>
                                    {/* Product Details & Association */}
                                    <span className="lead text-muted">Details</span>
                                    {/*<span className="text-muted"><em> set asset details and attributes</em></span>*/}
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col lg={8} md={8}>
                                            <h4>{`${product.brand.name} ${product.modelNumber}`}
                                                <br/>
                                                <p className="pt-1 text-thin text-sm"><em>{product.description}</em></p>
                                            </h4>
                                        </Col>
                                        {/* Product Media */}
                                        <Col lg={4} md={4}>
                                            {/* Hero Image Row */}
                                            <Row>
                                                <Col lg={12} md={12}>
                                                    <div className="mb-2 text-center">
                                                        <img
                                                            className="img-fluid rounded"
                                                            style={{
                                                                width: '200px',
                                                                height: 'auto'
                                                            }}
                                                            src={product.media[0].url}
                                                            alt={product.media[0].alt}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            {/* Thumbnails */}
                                            <Row>
                                                <Col lg={12} md={12}>
                                                    <div className="mb-2" style={{
                                                        overflowX: 'auto',
                                                        whiteSpace: 'nowrap'
                                                    }}>
                                                        {product.media && product.media.length > 1 && product.media.map((media, idx) => {
                                                            if (idx === 0) return (
                                                                <img
                                                                    className="img-fluid rounded thumb64"
                                                                    src={media.url}
                                                                    alt={media.alt}
                                                                />
                                                            );

                                                            return (
                                                                <img
                                                                    className="ml-1 img-fluid rounded thumb64"
                                                                    src={media.url}
                                                                    alt={media.alt}
                                                                />
                                                            )
                                                        })}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </ContentWrapper>
        );
    }
}
