/**
 * Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */


'use strict';

import alt from '../../../../alt';

class ProductActions {
    constructor() {
        this.generateActions(
            'init',
        );
    }
}

export default alt.createActions(ProductActions);
