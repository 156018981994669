/**
 * @copyright Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

import React, { Component } from 'react';
import {
    Route, Switch,
} from 'react-router-dom';
import TeamContainer from './TeamContainer.jsx';
import {URLS} from '../../../../common/constants';
import CreateTeamMemberContainer from './CreateTeamMemberContainer.jsx';

export default class TeamRouter extends Component {
    static propTypes = {

    };

    static defaultProps = {

    };

    render() { // eslint-disable-line
        const _URLS = URLS.organizations.team;
        return (
            <Switch>
                <Route path={_URLS.createTeamMember} exact component={CreateTeamMemberContainer}/>
                <Route path={_URLS.base} component={TeamContainer} />
            </Switch>
        );
    }
}
