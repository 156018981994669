/**
 * @copyright Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

import React, { Component } from 'react';
import {
    Route,
    Switch,
} from 'react-router-dom';
import ApplicationsContainer from './ApplicationsContainer.jsx';
import CreateApplicationContainer from './CreateApplicationContainer.jsx';

import {URLS} from '../../../../common/constants';

export default class ApplicationsRouter extends Component {
    static propTypes = {

    };

    static defaultProps = {

    };

    render() { // eslint-disable-line
        const _URLS = URLS.organizations.applications;
        return (
            <Switch>
                <Route path={_URLS.createApplication} exact component={CreateApplicationContainer}/>
                <Route path={_URLS.base} component={ApplicationsContainer} />
            </Switch>
        );
    }
}
