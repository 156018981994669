/**
 * Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

import config from '../../../../config';
import {get} from '../../../common/services/ApiService';

const USAGE_STATISTICS_ENDPOINT = `${config.services.ATLAS.href}/usage/statistics`;
const USAGE_RECENT_ENDPOINT = `${config.services.ATLAS.href}/usage/recent`;

export async function getUsageStatistics() {
    return get(true, USAGE_STATISTICS_ENDPOINT, {}, true);
}

export async function getRecentUsage() {
    return get(true, USAGE_RECENT_ENDPOINT, {}, true);
}

export async function initializeDashboard() {
    const stats = await getUsageStatistics();
    const recent = await getRecentUsage();
    return {stats, recent};
}
