/**
 * Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

import config from '../../../../config';
import {get, post} from '../../../common/services/ApiService';

const ASSETS_ENDPOINT = `${config.services.ATLAS.href}/assets`;

export async function getAssets() { // eslint-disable-line import/prefer-default-export
    return get(true, ASSETS_ENDPOINT, {}, true);
}

export async function createAsset(name, description) { // eslint-disable-line import/prefer-default-export
    const body = {
        name: name,
        description: description
    };
    return post(true, ASSETS_ENDPOINT, body, {}, true);
}
