/**
 * @copyright Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

import React, {Component} from 'react'; // eslint-disable-line no-unused-vars

import {
    Row,
    Col
} from 'reactstrap';
import {
    LabelSeries,
    Sunburst
} from 'react-vis';
import {EXTENDED_DISCRETE_COLOR_RANGE} from 'react-vis/dist/theme';
import D3FlareData from './d3-flare-example.json';

export default class TaxonomySunburst extends React.Component {
    state = {
        pathValue: false,
        data: decoratedData,
        finalValue: 'SUNBURST',
        clicked: false
    };

    render() {
        const {
            clicked,
            data,
            finalValue,
            pathValue
        } = this.state;
        return (
            <div>
                <Row>
                    <Col lg={12} md={12}>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <Sunburst
                                animation
                                className="basic-sunburst-example"
                                hideRootNode
                                onValueMouseOver={(node) => {
                                    if (clicked) {
                                        return;
                                    }
                                    const path = getKeyPath(node).reverse();
                                    const pathAsMap = path.reduce((res, row) => {
                                        res[row] = true;
                                        return res;
                                    }, {});
                                    this.setState({
                                        finalValue: path[path.length - 1],
                                        pathValue: path.join(' > '),
                                        data: updateData(decoratedData, pathAsMap)
                                    });
                                }}
                                onValueMouseOut={() => clicked // eslint-disable-line
                                    ? () => {}
                                    : this.setState({
                                        pathValue: false,
                                        finalValue: false,
                                        data: updateData(decoratedData, false)
                                    })
                                }
                                onValueClick={() => this.setState({clicked: !clicked})}
                                style={{
                                    stroke: '#ddd',
                                    strokeOpacity: 0.3,
                                    strokeWidth: '0.5'
                                }}
                                colorType="literal"
                                getSize={d => d.value}
                                getColor={d => d.hex}
                                data={data}
                                height={250}
                                width={200}
                            >
                                {finalValue && (
                                    <LabelSeries
                                        data={[{
                                            x: 0,
                                            y: 0,
                                            label: finalValue,
                                            style: {
                                                fontSize: '14px',
                                                textAnchor: 'middle'
                                            }
                                        }]}
                                    />
                                )}
                            </Sunburst>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} md={12}>
                        <div>
                            {clicked ? 'click to unlock selection' : 'click to lock selection'}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} md={12}>
                        <div>{pathValue}</div>
                    </Col>
                </Row>
            </div>
        );
    }
}

/**
 * Recursively work backwards from highlighted node to find path of valud nodes
 * @param {Object} node - the current node being considered
 * @returns {Array} an array of strings describing the key route to the current node
 */
function getKeyPath(node) {
    if (!node.parent) {
        return ['root'];
    }

    return [(node.data && node.data.name) || node.name].concat(
        getKeyPath(node.parent)
    );
}

/**
 * Recursively modify data depending on whether or not each cell has been selected by the hover/highlight
 * @param {Object} data - the current node being considered
 * @param {Object|Boolean} keyPath - a map of keys that are in the highlight path
 * if this is false then all nodes are marked as selected
 * @returns {Object} Updated tree structure
 */
function updateData(data, keyPath) {
    if (data.children) {
        data.children.map(child => updateData(child, keyPath));
    }
    // add a fill to all the uncolored cells
    if (!data.hex) {
        data.style = { // eslint-disable-line
            fill: EXTENDED_DISCRETE_COLOR_RANGE[5]
        };
    }
    data.style = { // eslint-disable-line
        ...data.style,
        fillOpacity: keyPath && !keyPath[data.name] ? 0.2 : 1
    };

    return data;
}

const decoratedData = updateData(D3FlareData, false);
