/**
 * @copyright Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

import pjson from '../package.json';

const NAME = `${pjson.name}`;
const VERSION = `${pjson.version}.${pjson.build}`;
const STORAGE_NAMESPACE_PREFIX = `rocketiq_${NAME.toLowerCase()}`;
const CONSTANTS = {
    CACHE_KEY_TOKEN: '$',
    CACHE_KEY_ACCOUNT: 'account',
    CACHE_KEY_API: 'api',
};

const config = {
    development: {
        env: 'development',
        name: NAME,
        version: VERSION,
        storageNamespace: `${STORAGE_NAMESPACE_PREFIX}.dev`,
        constants: CONSTANTS,
        services: {
            ATLAS: {
                href: 'http://localhost:3000/api'
                // href: 'https://dev-api.skunkwerks.io/api'
                // href: 'https://prod.rocketio.io/api'
            }
        }
    },
    test: {
        env: 'test',
        name: NAME,
        version: VERSION,
        storageNamespace: `${STORAGE_NAMESPACE_PREFIX}.test`,
        constants: CONSTANTS,
        services: {
            ATLAS: {
                href: 'https://prod.rocketiq.io/api'
            }
        }
    },
    production: {
        env: 'production',
        name: NAME,
        version: VERSION,
        storageNamespace: `${STORAGE_NAMESPACE_PREFIX}.prod`,
        constants: CONSTANTS,
        services: {
            ATLAS: {
                href: 'https://prod.rocketiq.io/api'
            }
        }
    }
};

// This is probably good enough for now
// https://facebook.github.io/create-react-app/docs/adding-custom-environment-variables
export default config[process.env.REACT_APP_RUNTIME_ENV];
