/* eslint-disable */

import React, { Component } from 'react';
import {
    Row,
    Col,
    Card,
    CardBody
} from 'reactstrap';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

import {APP_COLORS} from '../../../../common/constants';
import ContentWrapper from '../../../../common/components/layout/ContentWrapper';


am4core.useTheme(am4themes_animated);

export default class Plan extends Component {

    componentDidMount() {
        let chart = am4core.create('chartdiv', am4charts.XYChart);

        chart.paddingRight = 20;

        let data = [];
        let visits = 1000;
        for (let i = 1; i < 366; i++) {
            visits += Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 100);
            data.push({ date: new Date(2018, 0, i), name: 'name' + i, value: (visits < 0 ? 0 : visits) });
        }

        chart.data = data;

        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0;

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.tooltip.disabled = true;
        valueAxis.renderer.minWidth = 35;

        let series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.dateX = 'date';
        series.dataFields.valueY = 'value';
        series.stroke = am4core.color(APP_COLORS.purple);

        series.tooltipText = '{valueY.value}';
        // series.tooltip.label.fill = am4core.color(APP_COLORS.purple);

        series.tooltip.getFillFromObject = false;
        series.tooltip.background.fill = am4core.color(APP_COLORS.purple);
        series.tooltip.label.fill = am4core.color("#ffffff");

        chart.cursor = new am4charts.XYCursor();

        let scrollbarX = new am4charts.XYChartScrollbar();
        scrollbarX.series.push(series);
        chart.scrollbarX = scrollbarX;

        this.chart = chart;
    }

    componentWillUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
    }

    render() {
        const monthNames = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ];
        const usageStart = new Date(2018, 0, 1);
        const usageEnd = new Date(2018, 0, 366);
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>
                        Plan
                        <small className="mt-1">manage your subscription plan and billing details</small>
                    </div>
                </div>
                <Row>
                    <Col lg={12} md={12}>
                        <Card className="card-default">
                            <Row>
                                <Col lg={12} md={12}>
                                    <CardBody >
                                        <span className="h6 text-muted">[PLAN NAME]</span>
                                        <pre className="mt-2">Information about plan ability to manage (including updating billing info, aggregate (total), last invoice, etc)</pre>
                                    </CardBody>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} md={12}>
                        <Card className="card-default">
                            <Row>
                                <Col lg={12} md={12}>
                                    <CardBody >
                                        <span className="h6 text-muted">Usage</span>
                                        <span className="text-bold text-purple">{` ${monthNames[usageStart.getMonth()]} ${usageStart.getDay()}, ${usageStart.getFullYear()}`}</span>
                                        <em className="text-muted"> thru </em>
                                        <span className="text-bold text-purple">{` ${monthNames[usageEnd.getMonth()]} ${usageEnd.getDay()}, ${usageEnd.getFullYear()}`}</span>
                                        <div>
                                            <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>
                                        </div>
                                    </CardBody>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} md={12}>
                        <Card className="card-default">
                            <Row>
                                <Col lg={12} md={12}>
                                    <CardBody >
                                        <span className="h6 text-muted">Invoices</span>
                                        <pre className="mt-2">Tabluar set of invoices, most recent first - searchable, sortable?</pre>
                                    </CardBody>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </ContentWrapper>
        );
    }
};
