/**
 * @copyright Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

import React, { Component } from 'react';
import {
    Route,
} from 'react-router-dom';
import SystemContainer from './SystemContainer.jsx';

export default class SystemRouter extends Component {
    static propTypes = {

    };

    static defaultProps = {

    };

    render() { // eslint-disable-line
        return (
            <Route component={SystemContainer} />
        );
    }
}
