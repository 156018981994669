/**
 * Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

/* eslint-disable class-methods-use-this */

import React, { Component } from 'react';

import {getCopyright} from '../../utils';

export default class Footer extends Component {
    render() {
        return (
            <footer className="footer-container">
                {getCopyright()}
            </footer>
        );
    }
}
