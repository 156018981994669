/**
 * Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import {Link} from 'react-router-dom';
import {Input} from 'reactstrap';
import {ToastContainer, toast} from 'react-toastify';

import FormValidator from '../../components/forms/FormValidator';

import {getCopyright} from '../../utils';
import {login} from '../../services/AccountService';
import AccountActions from '../../actions/AccountActions';
import SpinnerOverlay from '../../components/SpinnerOverlay';

export default class Login extends Component {
    static propTypes = {
        location: PropTypes.object,
    };

    static defaultProps = {};

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            formLogin: {
                email: '',
                password: ''
            }
        };
    }

    componentDidMount() {
        const parsed = queryString.parse(this.props.location.search);
        if (parsed && parsed.confirmed) {
            toast('Account Confirmed! Please login.', {
                type: 'success',
                hideProgressBar: true,
                position: 'top-center'
            });
        }
    }

    /**
     * Validate input using onChange event
     * @param  {String} formName The name of the form in the state object
     * @return {Function} a function used for the event
     */
    validateOnChange = (event) => {
        const input = event.target;
        const form = input.form; // eslint-disable-line prefer-destructuring
        const value = input.type === 'checkbox' ? input.checked : input.value;

        const result = FormValidator.validate(input);

        this.setState({
            [form.name]: {
                ...this.state[form.name],
                [input.name]: value,
                errors: {
                    ...this.state[form.name].errors,
                    [input.name]: result
                }
            }
        });
    };

    onSubmit = (e) => {
        e.preventDefault();
        const form = e.target;
        const inputs = [...form.elements].filter(i => ['INPUT', 'SELECT'].includes(i.nodeName));
        const {errors, hasError} = FormValidator.bulkValidate(inputs);

        if (hasError) {
            this.setState({
                [form.name]: {
                    ...this.state[form.name],
                    errors
                }
            });
        } else {
            this.setState({
                loading: true,
                [form.name]: {
                    ...this.state[form.name],
                    errors
                }
            }, () => {
                login({
                    email: this.state.formLogin.email,
                    password: this.state.formLogin.password
                })
                    .then(account => AccountActions.login(account))
                    .catch(error => { // eslint-disable-line
                        this.setState({loading: false}, () => {
                            if (error.message === 'Unauthorized') {
                                toast('Check your credentials and try again.', {
                                    type: 'error',
                                    hideProgressBar: true,
                                    position: 'top-center'
                                });
                            } else {
                                toast(`There was a problem - ${error.message}`, {
                                    type: 'error',
                                    hideProgressBar: true,
                                    autoClose: false,
                                    position: 'top-center'
                                });
                            }
                        });
                    });
            });
        }
    };

    /* Simplify error check */
    hasError = (formName, inputName, method) => this.state[formName]
        && this.state[formName].errors
        && this.state[formName].errors[inputName]
        && this.state[formName].errors[inputName][method];

    render() {
        return (
            <div>
                <ToastContainer />
                {this.state.loading && <SpinnerOverlay/>}
                <div className="block-center mt-4 wd-xl">
                    <div className="card card-flat">
                        <div className="card-header text-center" style={{backgroundColor: '#6d48e5'}}>
                            <a href="https://web.rocketiq.io">
                                <img className="block-center rounded" style={{height: 35, width: 35}} src="img/icon-white.png" alt="Logo"/>
                                <span style={{color: '#ffffff', fontSize: '22px'}}>&nbsp;&nbsp;rocketIQ</span>
                            </a>
                        </div>
                        <div className="card-body">
                            <p className="text-center py-2">PLEASE LOGIN TO CONTINUE</p>
                            <form className="mb-3" name="formLogin" onSubmit={this.onSubmit}>
                                <div className="form-group">
                                    <div className="input-group with-focus">
                                        <Input
                                            type="email"
                                            name="email"
                                            className="border-right-0"
                                            placeholder="Enter email"
                                            invalid={this.hasError('formLogin', 'email', 'required') || this.hasError('formLogin', 'email', 'email')}
                                            onChange={this.validateOnChange}
                                            data-validate='["required", "email"]'
                                            value={this.state.formLogin.email}/>
                                        <div className="input-group-append">
                                            <span className="input-group-text text-muted bg-transparent border-left-0">
                                                <em className="fa fa-envelope"></em>
                                            </span>
                                        </div>
                                        {this.hasError('formLogin', 'email', 'required')
                                        && <span className="invalid-feedback">Field is required</span>}
                                        {this.hasError('formLogin', 'email', 'email')
                                        && <span className="invalid-feedback">Field must be valid email</span>}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="input-group with-focus">
                                        <Input
                                            type="password"
                                            id="id-password"
                                            name="password"
                                            className="border-right-0"
                                            placeholder="Password"
                                            invalid={this.hasError('formLogin', 'password', 'required')}
                                            onChange={this.validateOnChange}
                                            data-validate='["required"]'
                                            value={this.state.formLogin.password}
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text text-muted bg-transparent border-left-0">
                                                <em className="fa fa-lock"></em>
                                            </span>
                                        </div>
                                        <span className="invalid-feedback">Field is required</span>
                                    </div>
                                </div>
                                <div className="clearfix">
                                    <div className="text-center">
                                        <Link to="recover" className="text-muted">Forgot your password?</Link>
                                    </div>
                                </div>
                                <button className="btn btn-block btn-purple mt-3" type="submit">Login</button>
                            </form>
                            {/* <p className="pt-3 text-center">Need to Signup?</p> */}
                            {/* <Link to="register" className="btn btn-block btn-secondary">Register Now</Link> */}
                        </div>
                    </div>
                    <div className="p-3 text-center">
                        {getCopyright()}
                    </div>
                </div>
            </div>
        );
    }
}
