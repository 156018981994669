/**
 * Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

import React, {Component} from 'react';

import ToggleState from '../ToggleState';
import TriggerResize from '../TriggerResize';

/* eslint-disable class-methods-use-this */

class Header extends Component {
    componentDidMount() {

    }

    render() {
        return (
            <header className="topnavbar-wrapper">
                {/* START Top Navbar */}
                <nav className="navbar topnavbar">
                    {/* START navbar header */}
                    <div className="navbar-header">
                        <a className="navbar-brand" href="#/">
                            <div className="brand-logo">
                                {/* <em className="fas fa-rocket fa-2x"></em> */}
                                <img className="img-fluid" style={{height: 35, width: 35}} src="img/icon-white.png" alt="App Logo"/>
                                <span style={{color: '#ffffff', fontSize: '22px'}}>&nbsp;&nbsp;rocketIQ</span>
                            </div>
                            <div className="brand-logo-collapsed">
                                <img className="img-fluid" style={{height: 35, width: 35}} src="img/icon-white.png" alt="App Logo"/>
                            </div>
                        </a>
                    </div>
                    {/* END navbar header */}

                    {/* START Left navbar */}
                    <ul className="navbar-nav mr-auto flex-row">
                        <li className="nav-item">
                            {/* Button used to collapse the left sidebar. Only visible on tablet and desktops */}
                            <TriggerResize>
                                <ToggleState state="aside-collapsed">
                                    <a href="" className="nav-link d-none d-md-block d-lg-block d-xl-block">
                                        <em className="icon-menu"></em>
                                    </a>
                                </ToggleState>
                            </TriggerResize>
                            {/* Button to show/hide the sidebar on mobile. Visible on mobile only. */}
                            <ToggleState state="aside-toggled" nopersist={true}>
                                <a href="" className="nav-link sidebar-toggle d-md-none">
                                    <em className="icon-menu"></em>
                                </a>
                            </ToggleState>
                        </li>
                    </ul>
                    {/* END Left navbar */}
                    {/* START Right Navbar */}
                    <ul className="navbar-nav flex-row">
                        {/* START Offsidebar button */}
                        <li className="nav-item">
                            <ToggleState state="offsidebar-open" nopersist={true}>
                                <a id="offsidebar-toggler" className="nav-link" href="">
                                    <em className="icon-ghost fa-lg"></em>
                                </a>
                            </ToggleState>
                        </li>
                        {/* END Offsidebar menu */}
                    </ul>
                    {/* END Right Navbar */}
                </nav>
                {/* END Top Navbar */}
            </header>
        );
    }
}

export default Header;
