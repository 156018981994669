/**
 * Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

import alt from '../../../alt';

class AccountActions {
    constructor() {
        this.generateActions(
            'login',
            'logout'
        );
    }
}

export default alt.createActions(AccountActions);
