/**
 * @copyright Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

import React, {Component} from 'react'; // eslint-disable-line no-unused-vars

import SpinnerOverlay from '../../../../common/components/SpinnerOverlay';
import Applications from './Applications.jsx';
import ApplicationsStore from './ApplicationsStore';
import ApplicationsActions from './ApplicationsActions';
import {getApplications} from './ApplicationsService';

/**
 * ExploreContainer
 */
export default class ApplicationsContainer extends Component {
    static propTypes = {};

    static defaultProps = {};

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            applicationStore: ApplicationsStore.getState()
        };

        this.onChange = state => this.setState({applicationStore: state});
    }

    componentDidMount() {
        ApplicationsStore.listen(this.onChange);
        if (!this.state.applicationStore.initialized) {
            this.setState({
                loading: true
            }, () => {
                getApplications()
                    .then((applications) => {
                        this.setState({
                            loading: false
                        }, () => ApplicationsActions.init(applications));
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            });
        }
    }

    componentWillUnmount() {
        ApplicationsStore.unlisten(this.onChange);
    }

    render() { // eslint-disable-line
        return (
            <div>
                {this.state.loading && <SpinnerOverlay/>}
                <Applications {...this.props} applicationStore={this.state.applicationStore}/>
            </div>
        );
    }
}
