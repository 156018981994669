/**
 * @copyright Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

/* eslint-disable */

import React, { Component } from 'react';
import {Row, Col, Form, InputGroup, Input, InputGroupAddon, Button} from 'reactstrap';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

import ContentWrapper from '../../../common/components/layout/ContentWrapper';
import {APP_COLORS} from "../../../common/constants";

import geoData from '../../../common/data/Denver-GeoJson-Coords.json';

mapboxgl.accessToken = 'pk.eyJ1Ijoib3BzLWF0LWdldHJvY2tldGlxLWNvbSIsImEiOiJjazBwcWxyeGIwMGwzM21uYm9xYXl6ZWlnIn0.KJBFnLcINCV_5A1XgroYJw';

// https://blog.mapbox.com/mapbox-gl-js-react-764da6cc074a

export default class Explore extends Component {

    componentDidMount() {
        const map = this.map = new mapboxgl.Map({
            container: this.mapContainer,
            style: 'mapbox://styles/mapbox/light-v10',
            center: [-104.9915, 39.7420], // Denver CO
            // center: [-122.447303, 37.753574], // San Francisco
            // center: [-77.04, 38.907], // DC
            zoom: 12, // https://docs.mapbox.com/help/glossary/zoom-level/
            pitch: 45,
            bearing: -17.6,
            antialias: true
        });

        map.addControl(new mapboxgl.NavigationControl());
        // map.addControl(new mapboxgl.GeolocateControl({
        //     positionOptions: {
        //         enableHighAccuracy: true
        //     },
        //     trackUserLocation: true
        // }));

        map.addControl(new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl
        }), 'top-left');

        map.addControl(new SatelliteViewControl(), 'top-right');

        map.on('load', () => {
            var layers = map.getStyle().layers;

            var labelLayerId;
            for (var i = 0; i < layers.length; i++) {
                if (layers[i].type === 'symbol' && layers[i].layout['text-field']) {
                    labelLayerId = layers[i].id;
                    break;
                }
            }

            map.addLayer({
                'id': '3d-buildings',
                'source': 'composite',
                'source-layer': 'building',
                'filter': ['==', 'extrude', 'true'],
                'type': 'fill-extrusion',
                'minzoom': 15,
                'paint': {
                    'fill-extrusion-color': '#aaa',
                    // use an 'interpolate' expression to add a smooth transition effect to the
                    // buildings as the user zooms in
                    'fill-extrusion-height': [
                        "interpolate", ["linear"], ["zoom"],
                        15, 0,
                        15.05, ["get", "height"]
                    ],
                    'fill-extrusion-base': [
                        "interpolate", ["linear"], ["zoom"],
                        15, 0,
                        15.05, ["get", "min_height"]
                    ],
                    'fill-extrusion-opacity': .6
                }
            }, labelLayerId);

            map.addLayer({
                "id": "places",
                "type": "circle",
                "source": {
                    "type": "geojson",
                    "data": {
                        "type": "FeatureCollection",
                        "features": geoData
                    }
                },
                'paint': {
                    // make circles larger as the user zooms from z12 to z22
                    'circle-radius': {
                        'base': 1.75,
                        'stops': [[12, 2], [22, 180]]
                    },
                    // color circles using a match expression
                    // https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-match
                    'circle-color': [
                        'match',
                        ['get', 'system'],
                        'APPLIANCE', '#6D48E5',
                        'PLUMBING', '#6A91EB',
                        /* other */ '#e55e5e'
                    ]
                },
            });

            // When a click event occurs on a feature in the places layer, open a popup at the
            // location of the feature, with description HTML from its properties.
            map.on('click', 'places', (e) => {
                var coordinates = e.features[0].geometry.coordinates.slice();
                var description = e.features[0].properties.description;
                var system = e.features[0].properties.system;

                let html = null;
                if (system === 'APPLIANCE') {
                    html = `<div style="display: flex; flex-direction: column; align-items: center;">
                                <strong>${description}</strong>
                                <img
                                 style="height:128px;"
                                 src="img/data/KitchenAid-KSDB900ESS-0.tif"
                                 alt="KitchenAid - KSDB900ESS"/>
                                <strong>KitchenAid - KSDB900ESS</strong>
                                <p>7.1 cu. ft. Slide-In Dual Fuel Range with AquaLift Self-Cleaning True Convection Oven in Stainless Steel</p>
                            </div>`;
                } else {
                    html = `<div style="display: flex; flex-direction: column; align-items: center;">
                                <strong>${description}</strong>
                                <img
                                 style="height:128px;"
                                 src="img/sample/rheem-natural-gas-water-heaters-xg40t06ec36u1-64_1000.jpg"
                                 alt="RHEEM - XG40T06EC36U1"/>
                                <strong>RHEEM - XG40T06EC36U1</strong>
                                <p>Performance 40 Gal. Tall 6 Year 36,000 BTU Natural Gas Tank Water Heater</p>
                            </div>`;
                }

            // Ensure that if the map is zoomed out such that multiple
            // copies of the feature are visible, the popup appears
            // over the copy being pointed to.
                while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                }

                new mapboxgl.Popup()
                    .setLngLat(coordinates)
                    .setHTML(html.toString())
                    .addTo(map);
            });

            // Change the cursor to a pointer when the mouse is over the places layer.
            map.on('mouseenter', 'places', () => {
                map.getCanvas().style.cursor = 'pointer';
            });

            // Change it back to a pointer when it leaves.
            map.on('mouseleave', 'places', () => {
                map.getCanvas().style.cursor = '';
            });
        });
    }


    componentWillUnmount() {
        // this.map.off('click', this.onMapClick);
        // this.map = null;
        this.map.remove(); // map box
    }

    onMapClick = () => {
        console.log('map has been clicked');
    };

    render() {
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>
                        Explore
                        <small>discover installed products</small>
                    </div>
                </div>
                <Row>
                    <Col lg={12} md={12} xs={12}>
                        <div style={{
                            height: '75vh',
                            display: 'flex'
                        }}>
                            <div style={{flex: 1}} ref={el => this.mapContainer = el} >
                                {/*<div style={{*/}
                                    {/*position: 'absolute',*/}
                                    {/*backgroundColor: '#ffffff',*/}
                                    {/*width: '30%',*/}
                                    {/*zIndex: 1*/}
                                {/*}} className="mt-2 ml-2">*/}
                                    {/*<Form>*/}
                                        {/*<InputGroup>*/}
                                            {/*<Input*/}
                                                {/*placeholder="Search..."*/}
                                                {/*bsSize="lg"*/}
                                                {/*style={{*/}
                                                    {/*borderRight: 'none'*/}
                                                {/*}}*/}
                                            {/*/>*/}
                                            {/*<InputGroupAddon addonType="append">*/}
                                                {/*<Button*/}
                                                    {/*color="link"*/}
                                                    {/*className="text-bold"*/}
                                                    {/*style={{*/}
                                                        {/*color: APP_COLORS["gray-darker"],*/}
                                                        {/*border: '1px solid #dde6e9',*/}
                                                        {/*borderLeft: 'none',*/}
                                                        {/*textDecoration: 'none'*/}
                                                    {/*}}*/}
                                                {/*><em className="icon-magnifier fa-lg"/></Button>*/}
                                            {/*</InputGroupAddon>*/}
                                        {/*</InputGroup>*/}
                                    {/*</Form>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </Col>
                </Row>
            </ContentWrapper>
        );
    }
}

class SatelliteViewControl {
    onAdd(map) {
        this._map = map;
        this._container = document.createElement('div');
        this._container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
        this._container.innerHTML = '<button className="mapboxgl-ctrl-icon"><i class="far fa-map"></i></button>';
        // this._container.className = 'mapboxgl-ctrl mapboxgl-ctrl-icon mapboxgl-ctrl-geolocate';
        // this._container.type = 'button';

        // this._container.style.backgroundColor = 'white';
        // this._container.style.width = '50px';
        // this._container.style.height = '50px';

        this._container.onclick = () => {
            console.log('buttonClicked');
            this._map.setStyle('mapbox://styles/mapbox/satellite-v9');
            this._map.setPitch(0);
            // TODO: Toggle On/Off Satellite View - rebuild layers (eg do we want building layers and dots on satelite view?
        };
        return this._container;
    }

    onRemove() {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
    }
}