/**
 * Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

import _ from 'lodash';
import config from '../../../../../config';
import {get, post} from '../../../../common/services/ApiService';

const TEAM_ENDPOINT = `${config.services.ATLAS.href}/organization/team`;

export async function getTeam() { // eslint-disable-line import/prefer-default-export
    const team = await get(true, TEAM_ENDPOINT, {}, true);
    return team.map(t => _.assign({}, t, {
        name: `${t.profile.givenName} ${t.profile.surName}`
    }));
}


export async function createTeamMember(email, firstName, lastName) { // eslint-disable-line import/prefer-default-export
    const body = {
        givenName: firstName,
        surName: lastName,
        credentials: {
            email: email,
        }
    };
    return post(true, TEAM_ENDPOINT, body, {}, true);
}

export async function disableTeamMember(teamMember) {
    const endpoint = `${TEAM_ENDPOINT}/${teamMember.hid}/disable`;
    return post(true, endpoint, null, {}, true);
}

export async function activateTeamMember(teamMember) {
    const endpoint = `${TEAM_ENDPOINT}/${teamMember.hid}/activate`;
    return post(true, endpoint, null, {}, true);
}
