/**
 * @copyright Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

import React, {Component} from 'react'; // eslint-disable-line no-unused-vars

import SpinnerOverlay from '../../../../common/components/SpinnerOverlay';
import Team from './Team.jsx';
import TeamStore from './TeamStore';
import {getTeam} from './TeamService';
import TeamActions from './TeamActions';


/**
 * ExploreContainer
 */
export default class TeamContainer extends Component {
    static propTypes = {};

    static defaultProps = {};

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            teamStore: TeamStore.getState()
        };

        this.onChange = state => this.setState({teamStore: state});
    }

    componentDidMount() {
        TeamStore.listen(this.onChange);
        if (!this.state.teamStore.initialized) {
            this.setState({
                loading: true
            }, () => {
                getTeam()
                    .then((team) => {
                        this.setState({
                            loading: false
                        }, () => TeamActions.init(team));
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            });
        }
    }

    componentWillUnmount() {
        TeamStore.unlisten(this.onChange);
    }

    render() {
        return (
            <div>
                {this.state.loading && <SpinnerOverlay/>}
                <Team {...this.props} teamStore={this.state.teamStore} />
            </div>
        );
    }
}
