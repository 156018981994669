/**
 * Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';

const BasePage = props => (
    <div className="wrapper">
        { props.children }
    </div>
);

BasePage.propTypes = {
    children: PropTypes.element.isRequired
};

export default BasePage;
