/**
 * Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';


import React, {
    Component
} from 'react';
import {
    HashRouter,
    Route
} from 'react-router-dom';

import AppRouter from './AppRouter';

// Vendor dependencies
import './Vendor';
// Application Styles
import './styles/bootstrap.scss';
import './styles/app.scss';

/* eslint-disable class-methods-use-this */

class App extends Component {
    render() {
        // specify base href from env variable 'PUBLIC_URL'
        // use only if application isn't served from the root
        // for development it is forced to root only
        /* global PUBLIC_URL */
        // TODO: Think through this ....
        const basename = process.env.NODE_ENV === 'development' ? '/' : (PUBLIC_URL || '/');
        return (
            <HashRouter basename={basename}>
                <Route path="/" component={AppRouter} />
            </HashRouter>
        );
    }
}

export default App;
