/**
 * Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';

import Header from './Header';
import Sidebar from './Sidebar';
import Offsidebar from './Offsidebar';
import Footer from './Footer';

const Base = props => (
    <div className="wrapper">
        <Header />

        <Sidebar />

        <Offsidebar />

        <section className="section-container">
            { props.children }
        </section>

        <Footer />
    </div>
);


Base.propTypes = {
    children: PropTypes.element.isRequired
};

export default Base;
