/**
 * Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */


'use strict';

import _ from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button} from 'reactstrap';
import UserAvatar from 'react-user-avatar';
import {ToastContainer, toast} from 'react-toastify';

import {disableTeamMember, activateTeamMember} from './TeamService';
import TeamActions from './TeamActions';
import ContentWrapper from '../../../../common/components/layout/ContentWrapper';
import {APP_COLORS, URLS} from '../../../../common/constants';

export default class Team extends Component {
    static propTypes = {
        teamStore: PropTypes.object,
        history: PropTypes.object
    };

    static defaultProps = {};

    constructor(props) { // eslint-disable-line no-useless-constructor
        super(props);
    }

    render() {
        const team = this.props.teamStore.team; // eslint-disable-line
        return (
            <ContentWrapper>
                <ToastContainer />
                <div className="content-heading">
                    <div>Team
                        <small className="mt-1">manage your team</small>
                    </div>
                    <div className="ml-auto">
                        <Button
                            className="text-thin"
                            color="green"
                            size="lg"
                            onClick={() => this.props.history.push(URLS.organizations.team.createTeamMember)}>
                            CREATE TEAM MEMBER
                        </Button>
                    </div>
                </div>
                {team && team.length > 0 && (
                    <div>
                        <div className="list-group">
                            {team.map(tm => <TeamMember {...this.props} key={tm.id} teamMember={tm}/>)}
                        </div>
                    </div>
                )}
            </ContentWrapper>
        );
    }
}


class TeamMember extends Component {
    static propTypes = {
        teamMember: PropTypes.object,
        disableTeamMember: PropTypes.func
    };

    static defaultProps = {};

    constructor(props) {
        super(props);
        this.state = {
            disabled: false
        };
    }

    async disableTeamMember(teamMember) {
        this.setState({
            disabled: true
        }, async () => {
            const response = await disableTeamMember(teamMember);
            // TODO: This is pretty volatile; a work around for not having an individual getter on the backend
            this.setState({disabled: false}, () => {
                TeamActions.updateTeamMember({hid: teamMember.hid, statusId: 'DISABLED'});
                toast(`Success - ${response.message}`, {
                    type: 'success',
                    hideProgressBar: true,
                    autoClose: false,
                    position: 'bottom-right'
                });
            });
        });
    }

    async activateTeamMember(teamMember) {
        this.setState({
            disabled: true
        }, async () => {
            const response = await activateTeamMember(teamMember);
            // TODO: This is pretty volatile; a work around for not having an individual getter on the backend
            this.setState({disabled: false}, () => {
                TeamActions.updateTeamMember({hid: teamMember.hid, statusId: 'ACTIVE'});
                toast(`Success - ${response.message}`, {
                    type: 'success',
                    hideProgressBar: true,
                    autoClose: false,
                    position: 'bottom-right'
                });
            });
        });
    }

    render() {
        const tm = this.props.teamMember;
        const isOwner = _.includes(tm.roles, 'OWNER');
        return (
            <div className="list-group-item mb-2">
                <table className="wd-wide">
                    <tbody>
                        <tr>
                            <td className="wd-xs text-center">
                                <UserAvatar
                                    style={{
                                        display: 'inline-block',
                                        color: 'white'
                                    }}
                                    colors={[
                                        APP_COLORS.pink,
                                        APP_COLORS.primary,
                                        APP_COLORS.purple,
                                        APP_COLORS.yellow,
                                        APP_COLORS.info,
                                        APP_COLORS.warning,
                                        APP_COLORS.green,
                                        APP_COLORS.dark,
                                    ]}
                                    size="50"
                                    name={tm.name}
                                />
                            </td>
                            <td>
                                <div className="pr-2">
                                    <h4 className="mb-2">{tm.name} <small>{tm.roles}</small></h4>
                                    <small className="text-muted">{tm.email}</small>
                                </div>
                            </td>
                            <td className="wd-xs d-none d-lg-table-cell">
                                <div className="px-2">
                                    {tm.statusId === 'ACTIVE' && (
                                        <Button
                                            className="text-thin"
                                            outline
                                            color="danger"
                                            disabled={this.state.disabled || isOwner}
                                            onClick={() => this.disableTeamMember(tm)}
                                        >Disable</Button>
                                    )}
                                    {tm.statusId === 'DISABLED' && (
                                        <Button
                                            className="text-thin"
                                            outline
                                            color="green"
                                            disabled={this.state.disabled}
                                            onClick={() => this.activateTeamMember(tm)}
                                        >Activate</Button>
                                    )}
                                    {tm.statusId === 'CREATED' && (
                                        <div className="px-10">
                                            <p className="m-0 text-center">{tm.statusId}</p>
                                            {/* <small className="text-muted text-sm-center">Complete Setup!</small> */}
                                        </div>
                                    )}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}
