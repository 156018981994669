/**
 * @copyright Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

import React, { Component } from 'react';
import {
    Redirect,
    Route,
    Switch
} from 'react-router-dom';

import DashboardRouter from './views/dashboard/DashboardRouter.jsx';
import AssetsRouter from './views/assets/AssetsRouter.jsx';
import ExploreRouter from './views/explore/ExploreRouter.jsx';
import ToolsRouter from './views/tools/ToolsRouter.jsx';
import ManageRouter from './views/manage/ManageRouter.jsx';
import SystemRouter from './views/system/SystemRouter.jsx';

export default class AdminRouter extends Component {
    static propTypes = {

    };

    static defaultProps = {

    };

    render() { // eslint-disable-line
        return (
            <Switch>
                <Route path="/admin/dashboard" exact component={DashboardRouter}/>
                <Route path="/admin/assets" exact component={AssetsRouter}/>
                <Route path="/admin/explore" exact component={ExploreRouter}/>
                <Route path="/admin/tools" component={ToolsRouter}/>
                <Route path="/admin/manage" component={ManageRouter}/>
                <Route path="/admin/system" component={SystemRouter}/>
                <Redirect to="/admin/dashboard"/>
            </Switch>
        );
    }
}
