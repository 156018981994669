/**
 * Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

import alt from '../../../../alt';

class AssetsActions {
    constructor() {
        this.generateActions(
            'init',
            'create',
        );
    }
}

export default alt.createActions(AssetsActions);
