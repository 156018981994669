/**
 * Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

/* eslint-disable */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Row,
    Col,
    Button,
    Card,
    CardHeader,
    CardBody,
    Collapse,
    Form,
    Input,
    InputGroup,
    InputGroupAddon,
    Pagination,
    PaginationItem,
    PaginationLink,
} from 'reactstrap';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_usaLow from "@amcharts/amcharts4-geodata/usaLow";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

import ContentWrapper from '../../../common/components/layout/ContentWrapper';
import {URLS, APP_COLORS} from '../../../common/constants';

const PROPERTIES = 7321;
const SYSTEMS = 14152;
const ASSETS = 32944;

export default class Assets extends Component {
    static propTypes = {
        history: PropTypes.object
    };

    static defaultProps = {};

    constructor(props) { // eslint-disable-line no-useless-constructor
        super(props);
        this.state = {
            hoveredCell: false,
            results: [
                {
                    id: 1117,
                    hid: 'r572KbnWY9',
                    brand: {
                        name: 'Rheem',
                        display: 'Rheem',
                    },
                    modelNumber: 'XG40T06EC36U1',
                    description: 'Performance 40 Gal. Tall 6 Year 36,000 BTU Natural Gas Tank Water Heater',
                    version: 1,
                    created: '2018-11-10T19:12:24.431Z',
                    updated: '2018-11-10T19:12:24.431Z',
                    imgSrc: 'img/sample/rheem-natural-gas-water-heaters-xg40t06ec36u1-64_1000.jpg'
                },
                {
                    id: 1112,
                    hid: 'a323KbnS12',
                    brand: {
                        name: 'KITCHEN-AID',
                        display: 'KitchenAid',
                    },
                    modelNumber: 'KRFC302ESS',
                    description: '22 cu. ft. 36-Inch Width Counter Depth French Door Refrigerator with Interior Dispense',
                    version: 1,
                    created: '2018-11-10T19:12:24.431Z',
                    updated: '2018-11-10T19:12:24.431Z',
                    imgSrc: 'img/sample/stainless-steel-kitchenaid-french-door-refrigerators-krff302ess-64_1000.jpg'
                },
                {
                    id: 1001,
                    hid: 'z312jhen12x',
                    brand: {
                        name: 'KITCHEN-AID',
                        display: 'KitchenAid',
                    },
                    modelNumber: 'KSDB900ESS',
                    description: '30-Inch 5-Burner Dual Fuel Convection Slide-In Range with Baking Drawer',
                    version: 1,
                    created: '2018-11-10T19:12:24.431Z',
                    updated: '2018-11-10T19:12:24.431Z',
                    imgSrc: 'img/sample/hero-KSDB900ESS.tif'
                }
            ],
            assetMapCollapsed: false
        };
    }

    componentDidMount() {
        let chart = am4core.create("chartdiv", am4maps.MapChart);
        chart.maxZoomLevel = 64;

        // Set map definition
        chart.geodata = am4geodata_usaLow;

        // Set projection
        chart.projection = new am4maps.projections.AlbersUsa();

        // Add button
        var zoomOut = chart.tooltipContainer.createChild(am4core.ZoomOutButton);
        zoomOut.align = "right";
        zoomOut.valign = "top";
        zoomOut.margin(20, 20, 20, 20);
        zoomOut.events.on("hit", function() {
            console.log('selected zoom out, this is where we would clear our filters');
            if (currentSeries) {
                currentSeries.hide();
            }
            chart.goHome();
            zoomOut.hide();
            currentSeries = regionalSeries.US.series;
            currentSeries.show();
        });
        zoomOut.hide();

        // Create map polygon series
        var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
        polygonSeries.useGeodata = true;
        polygonSeries.calculateVisualCenter = true;
        // polygonSeries.exclude = ["US-CO"];

        // Configure series
        var polygonTemplate = polygonSeries.mapPolygons.template;
        polygonTemplate.tooltipText = "{name}";
        polygonTemplate.fill = chart.colors.getIndex(0);
        polygonTemplate.fill = am4core.color('#bbbec6');

        // Load data when map polygons are ready
        chart.events.on("ready", loadStores);

        // Loads store data
        function loadStores() {
            var loader = new am4core.DataSource();
            loader.url = "https://s3-us-west-2.amazonaws.com/s.cdpn.io/t-160/TargetStores.json";
            loader.events.on("parseended", function(ev) {
                setupStores(ev.target.data);
            });
            loader.load();
        }

        // Creates a series
        function createSeries(heatfield) {
            var series = chart.series.push(new am4maps.MapImageSeries());
            series.dataFields.value = heatfield;

            var template = series.mapImages.template;
            template.verticalCenter = "middle";
            template.horizontalCenter = "middle";
            template.propertyFields.latitude = "lat";
            template.propertyFields.longitude = "long";
            template.tooltipText = "{name}:\n[bold]{stores} assets[/]";

            var circle = template.createChild(am4core.Circle);
            circle.radius = 10;
            circle.fillOpacity = 0.7;
            circle.fill = am4core.color(APP_COLORS.purple);
            circle.verticalCenter = "middle";
            circle.horizontalCenter = "middle";
            circle.nonScaling = true;

            var label = template.createChild(am4core.Label);
            label.text = "{stores}";
            label.fill = am4core.color('#ffffff');
            label.verticalCenter = "middle";
            label.horizontalCenter = "middle";
            label.nonScaling = true;

            var heat = series.heatRules.push({
                target: circle,
                property: "radius",
                min: 10,
                max: 30
            });

            // Set up drill-down
            series.mapImages.template.events.on("hit", function(ev) {

                // Determine what we've clicked on
                var data = ev.target.dataItem.dataContext;

                console.log(data, 'selected item, need to filter if picking the same thing');

                // No id? Individual store - nothing to drill down to further
                if (!data.target) {
                    return;
                }

                // Create actual series if it hasn't been yet created
                if (!regionalSeries[data.target].series) {
                    regionalSeries[data.target].series = createSeries("count");
                    regionalSeries[data.target].series.data = data.markerData;
                }

                // Hide current series
                if (currentSeries) {
                    currentSeries.hide();
                }

                // Control zoom
                if (data.type == "state") {
                    var statePolygon = polygonSeries.getPolygonById("US-" + data.state);
                    chart.zoomToMapObject(statePolygon);
                }
                else if (data.type == "city") {
                    chart.zoomToGeoPoint({
                        latitude: data.lat,
                        longitude: data.long
                    }, 64, true);
                }
                zoomOut.show();

                // Show new targert series
                currentSeries = regionalSeries[data.target].series;
                currentSeries.show();
            });

            return series;
        }

        var regionalSeries = {};
        var currentSeries;

        function setupStores(data) {

            // Init country-level series
            regionalSeries.US = {
                markerData: [],
                series: createSeries("stores")
            };

            // Set current series
            currentSeries = regionalSeries.US.series;

            // Process data
            am4core.array.each(data.query_results, function(store) {

                // Get store data
                var store = {
                    state: store.MAIL_ST_PROV_C,
                    long: am4core.type.toNumber(store.LNGTD_I),
                    lat: am4core.type.toNumber(store.LATTD_I),
                    location: store.co_loc_n,
                    city: store.mail_city_n,
                    count: am4core.type.toNumber(store.count)
                };

                // Process state-level data
                if (regionalSeries[store.state] == undefined) {
                    var statePolygon = polygonSeries.getPolygonById("US-" + store.state);
                    if (statePolygon) {

                        // Add state data
                        regionalSeries[store.state] = {
                            target: store.state,
                            type: "state",
                            name: statePolygon.dataItem.dataContext.name,
                            count: store.count,
                            stores: 1,
                            lat: statePolygon.visualLatitude,
                            long: statePolygon.visualLongitude,
                            state: store.state,
                            markerData: []
                        };
                        regionalSeries.US.markerData.push(regionalSeries[store.state]);

                    }
                    else {
                        // State not found
                        return;
                    }
                }
                else {
                    regionalSeries[store.state].stores++;
                    regionalSeries[store.state].count += store.count;
                }

                // Process city-level data
                if (regionalSeries[store.city] == undefined) {
                    regionalSeries[store.city] = {
                        target: store.city,
                        type: "city",
                        name: store.city,
                        count: store.count,
                        stores: 1,
                        lat: store.lat,
                        long: store.long,
                        state: store.state,
                        markerData: []
                    };
                    regionalSeries[store.state].markerData.push(regionalSeries[store.city]);
                }
                else {
                    regionalSeries[store.city].stores++;
                    regionalSeries[store.city].count += store.count;
                }

                // Process individual store
                regionalSeries[store.city].markerData.push({
                    name: store.location,
                    count: store.count,
                    stores: 1,
                    lat: store.lat,
                    long: store.long,
                    state: store.state
                });

            });

            regionalSeries.US.series.data = regionalSeries.US.markerData;
        }

        this.chart = chart;
    }

    componentWillUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
    }

    render() {
        const results = this.state.results;
        return (
            <ContentWrapper>
                <div className="content-heading">
                <div>
                    Assets
                    <small>create and manage assets</small>
                </div>
                <div className="ml-auto">
                    <Button
                        className="text-thin"
                        color="green"
                        size="lg"
                        onClick={() => this.props.history.push(URLS.organizations.assets.createAsset)}>
                        CREATE ASSET
                    </Button>
                </div>
            </div>
                <Row>
                    <Col lg={12} md={12}>
                        <Card className="card-default">
                            <Row>
                                <Col lg={12} md={12}>
                                    <CardHeader>
                                        <span className="text-muted">
                                            <span className="text-purple text-bold">{PROPERTIES.toLocaleString()}</span> properties,&nbsp;
                                            <span className="text-bold text-purple">{SYSTEMS.toLocaleString()}</span> systems,&nbsp;
                                            <span className="text-bold text-purple">{ASSETS.toLocaleString()}</span> assets.
                                        </span>
                                        <div className="fa-pull-right">
                                            <Button
                                                color="link"
                                                className="text-muted"
                                                onClick={() => this.setState({assetMapCollapsed: !this.state.assetMapCollapsed})}
                                            >
                                                {!this.state.assetMapCollapsed && (
                                                    <em className="fa fa-minus"/>
                                                )}
                                                {this.state.assetMapCollapsed && (
                                                    <em className="fa fa-plus"/>
                                                )}
                                            </Button>
                                        </div>
                                    </CardHeader>
                                    <Collapse isOpen={!this.state.assetMapCollapsed}>
                                        <CardBody>
                                            <div id="chartdiv" style={{ width: "100%", height: "400px" }}></div>
                                        </CardBody>
                                    </Collapse>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} md={12}>
                        <Card className="card-default mb-1">
                            <Row>
                                <Col lg={12} md={12}>
                                    <CardBody >
                                        <span className="lead text-muted">Search</span>
                                        <div className="mt-2">
                                            <Form>
                                                <InputGroup>
                                                    <Input
                                                        placeholder="Enter a state, city, address, asset type, brand, sku..."
                                                        bsSize="lg"
                                                        style={{
                                                            borderRight: 'none'
                                                        }}
                                                    />
                                                    <InputGroupAddon addonType="append">
                                                        <Button
                                                            color="link"
                                                            className="text-bold"
                                                            style={{
                                                                color: APP_COLORS["gray-darker"],
                                                                border: '1px solid #dde6e9',
                                                                borderLeft: 'none',
                                                                textDecoration: 'none'
                                                            }}
                                                        ><em className="icon-magnifier fa-lg"/></Button>
                                                    </InputGroupAddon>
                                                </InputGroup>
                                            </Form>
                                        </div>
                                        <div className="mt-2">
                                            <span className="lead text-muted">Results</span><span className="text-muted"><em> found </em><span className="text-bold text-purple">27,276</span><em> assets</em></span>
                                            <div className="fa-pull-right text-muted">
                                                <Button
                                                    color="link"
                                                    className="pl-1 pr-1"
                                                    onClick={() => console.log('request to filter assets')}
                                                >Filter</Button>&nbsp;|&nbsp;
                                                <Button
                                                color="link"
                                                className="pl-1 pr-1"
                                                onClick={() => console.log('request to clear applied search and filters')}
                                                >Reset</Button>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Col>
                            </Row>
                        </Card>
                        <Row>
                            <Col lg={12} md={12}>
                                {results && results.length > 0 && (
                                    <div>
                                        <div className="list-group">
                                            {results.map((r, i) => <Result key={r.id} result={r} indexPos={i}/>)}
                                        </div>
                                    </div>
                                )}
                            </Col>
                        </Row>
                        <Card>
                            <Row>
                                <Col lg={12} md={12}>
                                    <CardBody className="pt-0 pb-0">
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                            <span className="text-muted"><em>Showing 1 to 20 of 7321</em></span>
                                            <div style={{
                                                display: 'flex',
                                                flex: 1,
                                                justifyContent: 'flex-end'
                                            }}>
                                                {/* TODO Spent too much time on this already, the underlying UL element as mt-0 applied preventing centering correctly*/}
                                                <Pagination size="sm" className="mt-3">
                                                    <PaginationItem>
                                                        <PaginationLink previous href="#" />
                                                    </PaginationItem>
                                                    <PaginationItem>
                                                        <PaginationLink href="#">
                                                            1
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                    <PaginationItem>
                                                        <PaginationLink href="#">
                                                            2
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                    <PaginationItem>
                                                        <PaginationLink next href="#" />
                                                    </PaginationItem>
                                                </Pagination>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </ContentWrapper>
        );
    }
}

class Result extends Component {
    static propTypes = {
        result: PropTypes.object,
        indexPos: PropTypes.number
    };

    static defaultProps = {};

    constructor(props) {
        super(props);
        this.state = {
            showProtected: true
        };
    }

    render() {
        const result = this.props.result;
        let listGroupItemClassName = 'list-group-item mb-1';
        // if (this.props.indexPos === 0) listGroupItemClassName = 'list-group-item border-top-0';
        return (
            <div className={listGroupItemClassName}>
                <table className="wd-wide">
                    <tbody>
                    <tr>
                        <td className="wd-xs">
                            <div className="px-2">
                                <img className="img-fluid rounded thumb64" src={result.imgSrc} alt="Dummy" />
                            </div>
                        </td>
                        <td>
                            <div className="px-2">
                                <h4 className="mb-2">{result.brand.display} {result.modelNumber}<small className="text-muted">  {result.hid}</small></h4>
                                <small className="text-muted">{result.description}</small>
                            </div>
                        </td>
                        <td className="wd-xs d-none d-lg-table-cell">
                            <div className="px-2">
                                <Button
                                    className="text-thin"
                                    outline
                                    color="green"
                                    onClick={() => console.log('Im a button and i\'ve been clicked')}
                                >View</Button>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}
