/**
 * @copyright Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

import ContentWrapper from '../../../common/components/layout/ContentWrapper';

export default class Match extends Component {
    render() { // eslint-disable-line class-methods-use-this
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>
                        Match
                        <small>tools for matching your assets</small>
                    </div>
                </div>
                <Row>
                    <Col xs={12} className="text-center">
                        <p className="text-muted">
                            Under Construction. Updates coming soon!
                        </p>
                    </Col>
                </Row>
            </ContentWrapper>
        );
    }
}
