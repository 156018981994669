/**
 * @copyright Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

import React, { Component } from 'react';
import {
    Route, Switch,
} from 'react-router-dom';

import AccountsContainer from './AccountsContainer.jsx';
import OrganizationsContainer from './OrganizationsContainer.jsx';

export default class ManageRouter extends Component {
    static propTypes = {

    };

    static defaultProps = {

    };

    render() { // eslint-disable-line
        return (
            <Switch>
                <Route exact path="/admin/manage/accounts" component={AccountsContainer} />
                <Route exact path="/admin/manage/organizations" component={OrganizationsContainer} />
            </Switch>
        );
    }
}
