/**
 * Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */


'use strict';

import alt from '../../../../../alt';

class TeamActions {
    constructor() {
        this.generateActions(
            'init',
            'updateTeamMember'
        );
    }
}

export default alt.createActions(TeamActions);
