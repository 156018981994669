/**
 * Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

import config from '../../../config';
import * as cache from '../cache';
import {get, post} from './ApiService';

const LOGIN_ENDPOINT = `${config.services.ATLAS.href}/account/login`;
const LOGOUT_ENDPOINT = `${config.services.ATLAS.href}/account/logout`;
const ACCOUNT_ENDPOINT = `${config.services.ATLAS.href}/account`;
const REQUEST_PASSWORD_RESET_ENDPOINT = `${config.services.ATLAS.href}/account/request-reset-password`;
const RESET_PASSWORD_ENDPOINT = `${config.services.ATLAS.href}/account/reset-password`;

/**
 * Check to see if a session exists
 * @returns {boolean}
 */
export function isLoggedIn() {
    if (cache.get(config.constants.CACHE_KEY_TOKEN) && cache.get(config.constants.CACHE_KEY_TOKEN)) return true;
    return false;
}

/**
 * Attempts to log the account holder in
 */
export async function login(credentials) {
    const body = {
        email: credentials.email,
        password: credentials.password
    };
    const token = await post(false, LOGIN_ENDPOINT, body, {}, false);
    cache.set(config.constants.CACHE_KEY_TOKEN, token);
    const account = await get(true, ACCOUNT_ENDPOINT);
    cache.set(config.constants.CACHE_KEY_ACCOUNT, account);
    cache.set(config.constants.CACHE_KEY_API, account.apiKey);
    return account;
}

export async function logout() {
    try {
        await post(true, LOGOUT_ENDPOINT, null, {}, false);
        cache.del(config.constants.CACHE_KEY_TOKEN);
        cache.del(config.constants.CACHE_KEY_ACCOUNT);
        cache.del(config.constants.CACHE_KEY_API);
    } catch (error) {
        console.log(error);
        cache.del(config.constants.CACHE_KEY_TOKEN);
        cache.del(config.constants.CACHE_KEY_ACCOUNT);
        cache.del(config.constants.CACHE_KEY_API);
    }
}

export function getAccount() {
    return cache.get(config.constants.CACHE_KEY_ACCOUNT);
}

export async function requestResetPassword(email) {
    const body = {
        email: email
    };
    await post(false, REQUEST_PASSWORD_RESET_ENDPOINT, body, {}, false);
    return Promise.resolve();
}

export async function resetPassword(password, token) {
    const body = {
        password: password
    };
    await post(false, RESET_PASSWORD_ENDPOINT, body, {access_token: token}, false);
    return Promise.resolve();
}

export function getRoleMapping(account) {
    // Admin Mappings
    if (account.roles.includes('ROOT')) return 'ADMIN';
    if (account.roles.includes('ADMIN')) return 'ADMIN';
    // Developer Mappings
    if (account.roles.includes('OWNER')) return 'ORGANIZATION';
    if (account.roles.includes('DEVELOPER')) return 'ORGANIZATION';
    return undefined;
}

export function isOwner(account) {
    if (getRoleMapping(account) === 'ORGANIZATION') return account.roles.includes('OWNER');
    return false;
}

export function getName(profile) {
    let name = 'Galileo Galilei';
    if (profile.givenName && profile.surName) name = `${profile.givenName} ${profile.surName}`;
    return name;
}
