/**
 * Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */


'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import {
    Switch,
    Route,
    Redirect,
    withRouter
} from 'react-router-dom';
import {TransitionGroup, CSSTransition} from 'react-transition-group';

import Base from './js/common/components/layout/Base';
import BasePage from './js/common/components/layout/BasePage';

import LoginContainer from './js/common/views/login/LoginContainer.jsx';
import RecoverContainer from './js/common/views/recover/RecoverContainer.jsx';
import ResetContainer from './js/common/views/reset/ResetContainer.jsx';

import AdminRouter from './js/admin/AdminRouter.jsx';
import OrganizationRouter from './js/organization/OrganizationRouter.jsx';

import AccountStore from './js/common/stores/AccountStore';


// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname
const publicPages = [
    '/login',
    '/recover',
    '/reset',
    // '/400',
    // '/500',
];

const timeout = {enter: 500, exit: 500};

// Animations supported
//      'rag-fadeIn'
//      'rag-fadeInRight'
//      'rag-fadeInLeft'

const animationName = 'rag-fadeIn';

class AppRouter extends React.Component {
    static propTypes = {
        location: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        this.state = AccountStore.getState();

        this.onChange = state => this.setState(state);
    }

    componentDidMount() {
        AccountStore.listen(this.onChange);
    }

    componentWillUnmount() {
        AccountStore.unlisten(this.onChange);
    }

    render() {
        const {location} = this.props;
        const currentKey = location.pathname.split('/')[1] || '/';

        // If Authenticated and I'm a member of an organization
        if (this.state.authenticated && this.state.roleMapping === 'ORGANIZATION') {
            return (
                <Base>
                    <TransitionGroup>
                        <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                            <div>
                                <Route path="/" component={OrganizationRouter} />
                            </div>
                        </CSSTransition>
                    </TransitionGroup>
                </Base>
            );
        }

        // If Authenticated and I'm an administrator
        if (this.state.authenticated && this.state.roleMapping === 'ADMIN') {
            return (
                <Base>
                    <TransitionGroup>
                        <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                            <div>
                                <Route path="/" component={AdminRouter} />
                            </div>
                        </CSSTransition>
                    </TransitionGroup>
                </Base>
            );
        }

        if (!this.state.authenticated && publicPages.indexOf(location.pathname) > -1) {
            return (
                // Page Layout component wrapper
                <BasePage>
                    <Switch location={location}>
                        {/* See full project for reference */}
                        <Route exact path="/login" component={LoginContainer}/>
                        <Route path="/recover" component={RecoverContainer}/>
                        <Route path="/reset" component={ResetContainer}/>
                    </Switch>
                </BasePage>
            );
        }

        return (
            <Redirect to={{pathname: '/login'}} />
        );
    }
}

export default withRouter(AppRouter);
