/**
 * Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import {FadingCircle} from 'better-react-spinkit';
import {APP_COLORS} from '../constants';

// Note if this is changed the height/width oto support the absolute positioning of the element needs to be updated
// in src/styles/app/spinner.scss
const SPINNER_SIZE = 100;

const SpinnerOverlay = props => (
    <div className="loading-overlay">
        <FadingCircle
            size={SPINNER_SIZE}
            color={props.color}
        />
    </div>
);

SpinnerOverlay.propTypes = {
    color: PropTypes.string
};

SpinnerOverlay.defaultProps = {
    color: APP_COLORS.purple
};

export default SpinnerOverlay;
