/**
 * Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

export const APP_COLORS = {
    // primary: '#5d9cec',
    primary: '#1174ba',
    success: '#27c24c',
    info: '#23b7e5',
    warning: '#ff902b',
    danger: '#f05050',
    inverse: '#131e26',
    green: '#37bc9b',
    pink: '#f532e5',
    // purple: '#7266ba',
    purple: '#6d48e5',
    dark: '#3a3f51',
    yellow: '#fad732',
    'gray-darker': '#232735',
    'gray-dark': '#3a3f51',
    gray: '#dde6e9',
    'gray-light': '#e4eaec',
    'gray-lighter': '#edf1f2'
};

export const APP_MEDIAQUERY = {
    desktopLG: 1200,
    desktop: 992,
    tablet: 768,
    mobile: 480
};

export const URLS = {
    // TODO: If i want /org or another prefix everywhere this will need to be conditionally built
    common: {
        products: {
            base: '/products',
            feelingLucky: '/products/feeling-lucky',
        }
    },
    organizations: {
        assets: {
            base: '/org/assets',
            createAsset: '/org/assets/create',
        },
        applications: {
            base: '/org/manage/applications',
            createApplication: '/org/manage/applications/create',
        },
        team: {
            base: '/org/manage/team',
            createTeamMember: '/org/manage/team/create-team-member',
        }
    }
};
