/**
 * @copyright Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

import React, {Component} from 'react'; // eslint-disable-line no-unused-vars

import Plan from './Plan.jsx';

/**
 * ExploreContainer
 */
export default class PlanContainer extends Component {
    static propTypes = {};

    static defaultProps = {};

    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };

        this.onChange = onChange.bind(this);
    }

    render() { // eslint-disable-line
        return (
            <Plan/>
        );
    }
}

function onChange(state) {
    console.log('im changin', state);
}
