/* eslint-disable */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import ContentWrapper from '../../../../common/components/layout/ContentWrapper';
import {
    Row,
    Col,
    Button,
} from 'reactstrap';

import {URLS} from '../../../../common/constants';


export default class Applications extends Component {
    static propTypes = {
        applicationStore: PropTypes.object,
    };

    static defaultProps = {};

    constructor(props) {
        super(props);
        this.state = {
            applicationStore: null
        };
    }

    render() {
        const applications = this.props.applicationStore.applications;
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>Applications
                        <small className="mt-1">create and manage applications</small>
                    </div>
                    <div className="ml-auto">
                        <Button className="text-thin" color="green" size="lg" onClick={() => this.props.history.push(URLS.organizations.applications.createApplication)}>CREATE APPLICATION</Button>
                    </div>
                </div>
                {this.props.applicationStore.initialized && applications.length === 0 && (
                    <Row>
                        <Col md={12}  className="text-center">
                            <h2 className="text-thin">WHOOPS! NO APPLICATIONS TO SEE HERE</h2>
                            <p>
                                Looks like you don't have any applications. Click <Link to={URLS.organizations.applications.createApplication}>here</Link> to create your first one!
                            </p>
                        </Col>
                    </Row>
                )}
                {applications && applications.length > 0 && (
                    <div>
                        <div className="list-group">
                            {applications.map(a => <Application key={a.key} application={a}/>)}
                        </div>
                    </div>
                )}
            </ContentWrapper>
        );
    }
}


class Application extends Component {
    static propTypes = {
        application: PropTypes.object,
    };

    static defaultProps = {};

    constructor(props) {
        super(props);
        this.state = {
            showProtected: true
        };
    }

    render() {
        const apiKey = this.props.application.apiKey.key;
        const protectedApiKey = `${apiKey.substring(0, 3)}...${apiKey.substring(apiKey.length - 3, apiKey.length)}`;
        return (
            <div className="list-group-item mb-2">
                <table className="wd-wide">
                    <tbody>
                    <tr>
                        <td className="wd-xs">
                            <div className="px-2">
                                <img className="img-fluid rounded thumb64" src="img/dummy.png" alt="Dummy" />
                            </div>
                        </td>
                        <td>
                            <div className="px-2">
                                <h4 className="mb-2">{this.props.application.name}<small className="text-muted">  {this.props.application.key}</small></h4>
                                <small className="text-muted">{this.props.application.description}</small>
                            </div>
                        </td>
                        {this.state.showProtected && (
                            <td className="wd-xs d-none d-lg-table-cell">
                                <div className="px-2">
                                    <p className="m-0">API Key</p>
                                    <small className="text-muted">{protectedApiKey}</small>
                                </div>
                            </td>
                        )}
                        {!this.state.showProtected && (
                            <td className="wd-xs d-none d-lg-table-cell">
                                <div className="px-2">
                                    <p className="m-0 text-center">API Key</p>
                                    <small className="text-muted">{apiKey}</small>
                                </div>
                            </td>
                        )}
                        <td className="wd-xs d-none d-lg-table-cell">
                            <div className="px-2">
                                <Button
                                    className="text-thin"
                                    outline
                                    color="green"
                                    onClick={() => this.setState({showProtected: !this.state.showProtected})}
                                >Show</Button>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}