/**
 * Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

/* eslint-disable */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Alert,
    Card,
    CardBody,
    FormGroup,
    Input,
} from 'reactstrap';
import ContentWrapper from '../../../../common/components/layout/ContentWrapper';
import FormValidator from '../../../../common/components/forms/FormValidator';
import SpinnerOverlay from '../../../../common/components/SpinnerOverlay';

import ApplicationsActions from './ApplicationsActions';
import {createApplication} from './ApplicationsService';
import {URLS} from '../../../../common/constants';

export default class CreateApplication extends Component {
    static propTypes = {
        history: PropTypes.object
    };

    static defaultProps = {};

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            alertSuccess: null,
            alertFailure: null,
            formCreateApplication: {
                // 'name' is a reserved keyword using the angle framework apparently (and will bork your shit)
                iname: '',
                description: ''
            }
        };
    }

    // This likely can be pushed to a utility class (or a parent form class)
    validateOnChange = (event) => {
        const input = event.target;
        const form = input.form; // eslint-disable-line prefer-destructuring
        const value = input.type === 'checkbox' ? input.checked : input.value;

        const result = FormValidator.validate(input);

        this.setState({
            [form.name]: {
                ...this.state[form.name],
                [input.name]: value,
                errors: {
                    ...this.state[form.name].errors,
                    [input.name]: result
                }
            }
        });
    };

    onSubmit = (e) => {
        e.preventDefault();
        const form = e.target;
        // This may need to be updated based on the controls (inputs, selects, etc) in your form
        const inputs = [...form.elements].filter(i => ['INPUT', 'TEXTAREA'].includes(i.nodeName));
        const {errors, hasError} = FormValidator.bulkValidate(inputs);

        if (hasError) {
            this.setState({
                [form.name]: {
                    ...this.state[form.name],
                    errors
                }
            });
        } else {
            this.setState({
                loading: true,
                [form.name]: {
                    ...this.state[form.name],
                    errors
                }
            }, () => {
                createApplication(this.state.formCreateApplication.iname, this.state.formCreateApplication.description)
                    .then((application) => {
                        this.setState({
                            loading: false,
                            alertSuccess: `Application - ${application.name} - created and assigned key - ${application.key}`,
                            // Reset form state
                            formCreateApplication: {
                                iname: '', // This doesn't seem to be taking hold ....
                                description: ''
                            }
                        }, () => {
                            this.props.history.push(URLS.organizations.applications.base);
                            ApplicationsActions.create(application);
                        });
                    })
                    .catch(error => { // eslint-disable-line
                        this.setState({
                            loading: false,
                            alertFailure: `There was a problem - ${error.message}`
                        });
                    });
            });
        }
    };

    /* Simplify error check */
    hasError = (formName, inputName, method) => this.state[formName]
        && this.state[formName].errors
        && this.state[formName].errors[inputName]
        && this.state[formName].errors[inputName][method];

    render() {
        return (
            <ContentWrapper>
                {this.state.loading && <SpinnerOverlay/>}
                <div className="content-heading">
                    <div>Applications - Create
                        <small className="mt-1">provide a name, description and select create. We&#39;ll take care of the rest</small>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Card className="card-default">
                            <CardBody>
                                <form name="formCreateApplication" onSubmit={this.onSubmit}>
                                    <FormGroup>
                                        <label>Name</label>
                                        <Input
                                            type="text"
                                            name="iname"
                                            placeholder="Enter Name"
                                            invalid={this.hasError('formCreateApplication', 'iname', 'required')}
                                            onChange={this.validateOnChange}
                                            data-validate='["required"]'
                                            value={this.state.formCreateApplication.iname}
                                        />
                                        {this.hasError('formCreateApplication', 'iname', 'required')
                                        && <span className="invalid-feedback">Field is required</span>}
                                    </FormGroup>
                                    <FormGroup>
                                        <label>Description</label>
                                        <Input
                                            type="textarea"
                                            name="description"
                                            placeholder="Enter Description"
                                            invalid={this.hasError('formCreateApplication', 'description', 'required')}
                                            onChange={this.validateOnChange}
                                            data-validate='["required"]'
                                            value={this.state.formCreateApplication.description}
                                        />
                                        {this.hasError('formCreateApplication', 'description', 'required')
                                        && <span className="invalid-feedback">Field is required</span>}
                                    </FormGroup>
                                    {this.state.alertSuccess && (
                                        <Alert color="success">{this.state.alertSuccess}</Alert>
                                    )}
                                    {this.state.alertFailure && (
                                        <Alert color="danger">{this.state.alertFailure}</Alert>
                                    )}
                                    <div className="text-right">
                                        <button className="btn btn-green text-thin" type="submit">Create</button>
                                    </div>
                                </form>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}
