/**
 * @copyright Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

import React, {Component} from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';

import Product from './Product.jsx';
import ProductStore from './ProductStore';
import {feelingLucky} from './ProductService';
import ProductActions from './ProductActions';
import SpinnerOverlay from '../../components/SpinnerOverlay';

/**
 * ExploreContainer
 */
export default class ProductContainer extends Component {
    static propTypes = {
        location: PropTypes.object
    };

    static defaultProps = {};

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            productStore: ProductStore.getState()
        };

        this.onChange = state => this.setState({productStore: state});
    }

    componentDidMount() {
        ProductStore.listen(this.onChange);
        if (!this.state.productStore.initialized) {
            this.setState({
                loading: true
            }, () => {
                // TODO: Regext match on path; if feeling lucky; -> feelingLucky() otherwise getProduct(hid)
                // console.log(this.props.location);
                feelingLucky()
                    .then((product) => {
                        this.setState({
                            loading: false
                        }, () => ProductActions.init(product));
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            });
        }
    }

    componentWillUnmount() {
        ProductStore.unlisten(this.onChange);
    }

    render() { // eslint-disable-line
        return (
            <div>
                {this.state.loading && <SpinnerOverlay/>}
                <Product
                    {...this.props}
                    product={this.state.productStore.product}
                />
            </div>
        );
    }
}
