/**
 * Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

import alt from '../../../../../alt';

import ApplicationsActions from './ApplicationsActions';

class ApplicationsStore {
    constructor(actions) {
        this.state = _getInitState();
        this.bindActions(actions);
    }

    init(applications) {
        this.setState({
            initialized: true,
            applications: applications,
        });
    }

    create(application) {
        // TODO: This probably isn't even needed; we instead probably want to re-init the store (load up all apps)
        const _applictions = this.state.applications;
        _applictions.push(application);
        this.setState({
            applications: _applictions,
        });
    }
}

function _getInitState() {
    return {
        initialized: false,
        applications: [],
    };
}

export default alt.createStore(ApplicationsStore, 'ApplicationsStore', ApplicationsActions);
