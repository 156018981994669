/**
 * Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

import alt from '../../../../alt';

import ProductActions from './ProductActions';

class ProductStore {
    constructor(actions) {
        this.state = _getInitState();
        this.bindActions(actions);
    }

    init(product) {
        this.setState({
            initialized: true,
            product: product,
        });
    }
}

function _getInitState() {
    return {
        initialized: false,
        product: null,
    };
}

export default alt.createStore(ProductStore, 'ProductStore', ProductActions);
