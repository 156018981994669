/**
 * Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

import config from '../../../../../config';
import {get, post} from '../../../../common/services/ApiService';

const APPLICATIONS_ENDPOINT = `${config.services.ATLAS.href}/applications`;

export async function getApplications() { // eslint-disable-line import/prefer-default-export
    return get(true, APPLICATIONS_ENDPOINT, {}, true);
}

export async function createApplication(name, description) { // eslint-disable-line import/prefer-default-export
    const body = {
        name: name,
        description: description
    };
    return post(true, APPLICATIONS_ENDPOINT, body, {}, true);
}
