/**
 * @copyright Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

import React, { Component } from 'react';
import {
    Route,
    Switch
} from 'react-router-dom';

import PlanContainer from './PlanContainer.jsx';

export default class PlanRouter extends Component {
    static propTypes = {

    };

    static defaultProps = {

    };

    render() { // eslint-disable-line
        return (
            <Switch>
                <Route exact path="/org/manage/plan" component={PlanContainer} />
            </Switch>
        );
    }
}
