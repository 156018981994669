/**
 * Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

import Alt from 'alt';

export default new Alt({
    stateKey: 'state'
});
