/**
 * Copyright Skunkwerks Inc., 2018 All Rights Reserved
 */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import {ThreeBounce} from 'better-react-spinkit';
import {APP_COLORS} from '../constants';

const Progress = props => (
    <ThreeBounce
        size={props.size}
        color={props.color}
    />
);


Progress.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string
};

Progress.defaultProps = {
    size: 10,
    color: APP_COLORS.purple
};

export default Progress;
